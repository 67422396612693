// Makes specified props optional in a type
// https://stackoverflow.com/questions/43159887/make-a-single-property-optional-in-typescript
export type Optional<T, K extends keyof T> = Partial<T> & Omit<T, K>;

export type PartialRecord<K extends keyof any, T> = {
    [P in K]?: T;
};

// Allows to properly type Object.entries()
// https://stackoverflow.com/a/60142095
export type Entries<T> = {
    [K in keyof T]: [K, T[K]];
}[keyof T][];

export type YesNo = 'YES' | 'NO';

export type YesNoOptional = YesNo | '';

export type SelectOption<T = string> = {
    value: T;
    label: string | number | JSX.Element;
};

export type SelectOptions<T = string> = SelectOption<T>[];

export type Mutable<T> = {
    -readonly [P in keyof T]: T[P] extends ReadonlyArray<infer U>
        ? Mutable<U>[]
        : Mutable<T[P]>;
};

/**
 * LabelKeyMapping creates an object where each key (from a union of strings)
 * maps to an object with:
 * - id: the key itself
 * - labelKey: a string label
 *
 * Example: For T = "A" | "B":
 * { A: { id: "A", labelKey: string }, B: { id: "B", labelKey: string } }
 */
export type LabelKeyMapping<T extends string> = {
    [K in T]: { id: K; labelKey: string };
  };

export type DeepMutable<T> = { -readonly [P in keyof T]: DeepMutable<T[P]> };

// ValueOf<T> is a utility type that returns the union of all the values in an object.
export type ValueOf<T> = T[keyof T];

export type AmountFrequency = {
    amount: number;
    frequency: Frequency;
    ratioAmount?: number;
    ratio?: number;
};

export type AmountRecurringFrequency = {
    amount: number;
    frequency?: RecurringFrequency;
};

export enum SemiMonthlyFrequency {
    NONE = '',
    FIRST_AND_FIFTEENTH = 'FIRST_AND_FIFTEENTH',
    SECOND_AND_SIXTEENTH = 'SECOND_AND_SIXTEENTH',
}

export type Frequency =
    | 'ANNUALLY'
    | 'SEMIANNUALLY'
    | 'QUARTERLY'
    | 'MONTHLY'
    | 'SEMIMONTHLY'
    | 'BIWEEKLY'
    | 'ACCELERATED_BIWEEKLY'
    | 'WEEKLY'
    | 'ACCELERATED_WEEKLY'
    | 'DAILY';

export type RecurringFrequency =
    | Frequency
    | ('ACCELERATED_BIWEEKLY' | 'ACCELERATED_WEEKLY' | 'DAILY');

export type PaginatedResponse<T = any> = {
    currentPage: number;
    results: T[];
    totalCount: number;
    totalPages: number;
};

export type Percentage25Increase =
    | '0_PERCENT'
    | '25_PERCENT'
    | '50_PERCENT'
    | '75_PERCENT'
    | '100_PERCENT';

export enum MapsStreetView {
    Roadmap = 'roadmap',
    Satellite = 'satellite',
    Directions = 'directions',
}

export type MapsStreetViewLiteral = `${MapsStreetView}`;

export type UnderwriterChecklistPayload = {
    code: string;
    type: string;
    category: string;
    checked: boolean | null;
    systemVerified: boolean | null;
};
