import React, { useCallback } from 'react';

import { Trans } from '@lingui/react';
import { useRecoilValue } from 'recoil';

import { useClient } from 'providers/auth0';
import { useModal } from 'providers/modals/use-modal';
import { selectedApplication } from 'store/applications';

import { ButtonNav } from '../button';
import { AlertIcon } from '../icons/alert-icon';

type Props = {
    isSidebarOpen: boolean;
    buttonStyle: string;
    popper?: {
        isVisible: boolean;
        text: string;
    };
};

export const LogIssue = ({ isSidebarOpen, buttonStyle, popper }: Props) => {
    const { client } = useClient();
    const { open: openModal } = useModal('logIssue');
    const selectedApp = useRecoilValue(selectedApplication);

    const onClick = useCallback(() => {
        const params = new URLSearchParams({
            ...(client?.rid && { accountRid: `${client.rid}` }),
            ...(client?.rid && selectedApp?.id && { applicationId: `${selectedApp.id}` }),
        });

        navigator.clipboard.writeText(
            `${window.location.origin}/login?${params.toString()}`
        );
        openModal({ rid: client?.rid });
    }, [client?.rid, selectedApp?.id, openModal]);

    return (
        <ButtonNav
            justifyEnd={!isSidebarOpen}
            href="#"
            data-testid="nav-log-issues"
            onClick={onClick}
            popper={popper}
        >
            <div className={buttonStyle}>
                <AlertIcon />
            </div>
            {isSidebarOpen && (
                <div>
                    <Trans id="logIssue" />
                </div>
            )}
        </ButtonNav>
    );
};
