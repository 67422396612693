import { format, isValid, parseISO } from 'date-fns';
import numeral from 'numeral';

import { ApplicationMortgage } from 'types/application';
import { isEmpty } from 'utils';
import { EMPTY_BACKEND_DATES } from 'validations/yup-extended';

export const maxTwoDecimal = (value: number | string) => {
    if (isEmpty(value)) return '';

    // Regex to replace everything that is not a number or a dot
    const regex = /[^0-9\.]/g;

    // Convert value to a string
    let stringValue = value.toString();

    // Need to remove every - except if it's the first character
    if (stringValue.charAt(0) === '-') {
        stringValue = `-${stringValue.slice(1).replace(regex, '')}`;
    } else {
        stringValue = stringValue.replace(regex, '');
    }

    // Split the string in 2 sections (integer/fractional part)
    const sections = stringValue.split('.');

    const integerPart = sections[0];

    const fractionalPart = sections[1];

    const formattedIntegerPart =
        integerPart === '-' || integerPart === '-0'
            ? integerPart
            : numeral(integerPart).format('0,0');

    // Set the fractional part (if present) to have a max of 2 decimals
    if (fractionalPart) {
        return `${formattedIntegerPart}.${fractionalPart.slice(0, 2)}`;
    }

    // If a dot is found inside the string we add it at the end
    if (stringValue.indexOf('.') !== -1) {
        return `${formattedIntegerPart}.`;
    }

    return formattedIntegerPart;
};

export const twoDecimals = (value) => numeral(value).format('0,0.00');

export const formatMoney = (
    value: number | string,
    withTwoDecimals = false
): string => {
    // Regex to replace everything that is not a number a dot or a minus
    const regex = /[^0-9\.-]/g;

    if (
        isEmpty(typeof value === 'string' ? value.replace(regex, '') : value) ||
        value === '$ '
    ) {
        return '';
    }
    return `$ ${withTwoDecimals ? twoDecimals(value) : maxTwoDecimal(value)}`;
};
// money sign at the back of the string
export const formatMoneySign = (
    value: number | string,
    withTwoDecimals = false
): string => {
    if (isEmpty(value) || value === '$ ') {
        return '';
    }
    if (value < 0) {
        return `- ${
            withTwoDecimals ? twoDecimals(value) : maxTwoDecimal(value)
        } $`;
    }
    return `${withTwoDecimals ? twoDecimals(value) : maxTwoDecimal(value)} $`;
};

export const formatPercentage = (
    value: number | string,
    round = true
): string => {
    if (isEmpty(value) || value === '% ') {
        return '';
    }

    return `% ${round ? maxTwoDecimal(value) : value}`;
};

export const formatDateTime = (date: Date) =>
    `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;

export const formatDateShort = (date: string | Date) => {
    if (!date) {
        return '-';
    }

    return new Date(date).toLocaleDateString();
};

export const formatPhone = (value: string) => {
    if (!value) {
        return value;
    }
    const onlyNums = getOnlyNums(value);
    // if (value?.length) {
    //     // typing forward
    //     if (onlyNums.length === 3) {
    //         return onlyNums + '-';
    //     }
    //     if (onlyNums.length === 6) {
    //         return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-';
    //     }
    // }
    if (onlyNums.length <= 3) {
        return onlyNums;
    }
    if (onlyNums.length <= 6) {
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3);
    }
    return (
        onlyNums.slice(0, 3) +
        '-' +
        onlyNums.slice(3, 6) +
        '-' +
        onlyNums.slice(6, 10)
    );
};

export const numberNormalizer = (value) => {
    if (isEmpty(value)) {
        return undefined;
    }
    const val = value.toString().replace(/[^\d.-]/g, ''); // Remove all chars except numbers and . and negative (-)

    return +val;
};

export const moneyNormalizer = numberNormalizer;

export const booleanNormalizer = (value) =>
    typeof value === 'string' ? value === 'true' : value;

export const dateNormalizer = (value) =>
    typeof value === 'string' && EMPTY_BACKEND_DATES.includes(value)
        ? undefined
        : value;

export const normalizeDateToString = (
    date: string | Date,
    formatString: string = 'yyyy-MM-dd'
): string | null => {
    const parsedDate = typeof date === 'string' ? parseISO(date) : date;
    return isValid(parsedDate) ? format(parsedDate, formatString) : null;
};

export const formatNumberAsString = (value: string, integer: boolean) => {
    // Keep only digits, the decimal point, and the minus sign.
    const cleanedValue = value.replace(/[^\d.-]/g, '');

    const negative = cleanedValue.startsWith('-');

    // Remove the minus sign, keeping only digits and the decimal point.
    const remainingCharacter = cleanedValue.replace(/[^\d.]/g, '');

    const floatVal = negative ? `-${remainingCharacter}` : remainingCharacter;

    let intValue: string;

    if (floatVal === '-') {
        intValue = floatVal;
    } else {
        intValue = floatVal ? String(Math.trunc(+floatVal)) : '';
    }

    return integer ? intValue : floatVal;
};

/* eslint-disable no-magic-numbers */
export const formatAsSIN = (value: string) => {
    if (!value) {
        return value;
    }

    const onlyNums = value.replace(/[^\d\*]/g, '');
    if (onlyNums.length <= 3) {
        return onlyNums;
    }
    if (onlyNums.length <= 6) {
        return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3)}`;
    }

    return `${onlyNums.slice(0, 3)} ${onlyNums.slice(3, 6)} ${onlyNums.slice(
        6,
        9
    )}`;
};

/**
 *
 * @param value - string
 * @returns obfuscated string pattern `1** 4** 7**`
 */
export const formatObfuscatedSIN = (value: string) => {
    if (!value) {
        return value;
    }

    const onlyNums = getOnlyNums(value);

    // first digit of each group is not obfuscated
    if (onlyNums.length <= 3) {
        return `${onlyNums.slice(0, 1)}${onlyNums
            .slice(1, 3)
            .replace(/\d/g, '*')}`;
    }
    if (onlyNums.length <= 6) {
        return `${onlyNums.slice(0, 1)}${onlyNums
            .slice(1, 3)
            .replace(/\d/g, '*')} ${onlyNums.slice(3, 4)}${onlyNums
            .slice(4)
            .replace(/\d/g, '*')}`;
    }

    return `${onlyNums.slice(0, 1)}${onlyNums
        .slice(1, 3)
        .replace(/\d/g, '*')} ${onlyNums.slice(3, 4)}${onlyNums
        .slice(4, 6)
        .replace(/\d/g, '*')} ${onlyNums.slice(6, 7)}${onlyNums
        .slice(7, 9)
        .replace(/\d/g, '*')}`;
};
/* eslint-enable no-magic-numbers */

/**
 * Get numbers only from a string
 *
 * @param value - string to be formatted
 * @returns string with only numbers
 */
export const getOnlyNums = (value: string) => {
    if (!value) {
        return value;
    }
    const onlyNums = value.replace(/[^\d]/g, '');

    return onlyNums;
};

// checks if the closing date changes, if so, it overrides this value, if not, if the first payment date already exists, pass in the value, if it does not, check if the value is the same as the regular first payment date, if its not, pass the override value, if it is, pass null
export const determineFirstPaymentDate = (
    mortgageDetails: ApplicationMortgage,
    values: { firstPaymentDateOverride?: string; closingDate?: Date | string }
) => {
    const {
        firstPaymentDate,
        closingDate: existingClosingDate,
        firstPaymentDateOverride: existingFirstPaymentDateOverride,
    } = mortgageDetails;
    const { firstPaymentDateOverride, closingDate: newClosingDate } = values;
    if (!firstPaymentDateOverride || !existingClosingDate) return null;
    if (
        new Date(newClosingDate).toISOString().split('T')[0] !==
        existingClosingDate
    ) {
        return null;
    } else if (!!firstPaymentDate) {
        if (
            firstPaymentDate === firstPaymentDateOverride &&
            !existingFirstPaymentDateOverride
        ) {
            return null;
        } else {
            return firstPaymentDateOverride;
        }
    }
};
