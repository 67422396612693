import { TRANSACTION_TYPE, useTenantSetting } from '@nestoca/multi-tenant';
import * as R from 'ramda';
import {
    atom,
    atomFamily,
    selector,
    selectorFamily,
    useRecoilRefresher_UNSTABLE,
    useRecoilState,
    useRecoilValue,
    useSetRecoilState,
} from 'recoil';

import { APPLICATION_PROBLEMS_SIDEBAR_VIEW } from 'components/validate-application-sidebar/constants';
import { ApplicationProblemsSidebarView } from 'components/validate-application-sidebar/types';
import {
    ACTIVE_CHANGE_APPLICATION_TYPE_STATES,
    APPLICANTS_COLORS,
    CLOSING_COST_MULTIPLIER,
    EIGHTY_PERCENT,
    PARTNER,
} from 'constants/appConstants';
import { HIDDEN_SECTIONS, Problems, ProblemType } from 'constants/problem';
import { client as apiClient } from 'libs/api';
import { persistAtom } from 'store/persist-atom';
import {
    CATAllocationsResponse,
    type Application,
    type ApplicationEvent,
    type ApplicationProduct,
    type ApplicationSummary,
    type BestProductsType,
    type MissingServicingFields,
} from 'types/application';
import { HelocApplication } from 'types/heloc';
import { byType, getCurrentAddress, insertAtIndex, isEmpty } from 'utils';
import { adaptApplicantInfo } from 'utils/adapter';
import { getApplicationMainType } from 'utils/application-type';
import { getMonthsFromYearsMonths, getYearAmountFrequency } from 'utils/fns';
import {
    getAugmentedApplicationProblem,
    getVisibleProblemSectionsWithCount,
} from 'utils/validations/problems';
import {
    applicantInformationSchema,
    incomeArraySchema,
    targetPropertyNotFoundSchema,
    targetPropertySchema,
} from 'validations/application-states';

import { useRefreshEligibility } from './product-selection';
import { getQualification } from './qualification';
import { useRefreshRecoilState } from './use-refresh-recoil-state';

import type { SelectOptions } from 'types';
import type {
    Applicant,
    ApplicantCreditReport,
    AssetsGroupedByApplicant,
    CreditDecision,
    OtherIncomeGroupedByApplicant,
} from 'types/applicant';
import type {
    DocumentCategory,
    DocumentEntityDetails,
    DocumentEntityType,
    DocumentSubCategory,
} from 'types/documents';
import type {
    ApplicationProblem,
    ApplicationProblemAugmented,
    ApplicationProblemsGrouped,
    ApplicationProblemsSummary,
} from 'types/problems';
import type {
    OtherProperty,
    SubjectProperty,
    TaxAuthorityType,
} from 'types/property';

export const getApplicationById = selectorFamily({
    key: 'GetApplicationByIDFamily',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const applications = get(applicationListState);

            const found = applications.find(
                (application) => application.id === applicationId
            );

            return found;
        },
});

export const selectedApplication = selector({
    key: 'SelectedApplication',
    get: ({ get }) => {
        try {
            const currentApplicationId = get(currentApplicationIdState);
            return get(getApplicationById(currentApplicationId));
        } catch (error) {
            console.error('selectedApplication error', error);
            return undefined;
        }
    },
});

const applicationsListRequestIDState = atom({
    key: 'ApplicationsListRequestID',
    default: 0,
});

export const getProblemsValidationMode = atom({
    key: 'GetProblemsValidationState',
    default: false,
    effects_UNSTABLE: [persistAtom],
});

export const applicationsList = selector({
    key: 'ApplicationsList',
    get: async ({ get }) => {
        try {
            get(applicationsListRequestIDState); // Add request ID as a dependency
            const { data } = await apiClient.getApplications();

            const applications = data.map((application) => {
                const applicants = application.applicants;

                // add applicant color code for each applicant
                Object.values(application?.applicants || []).forEach(
                    (applicant, index) => {
                        applicants[applicant.applicantId] = {
                            ...applicant,
                            applicantColor: APPLICANTS_COLORS[index % 5],
                        };
                    }
                );

                return { ...application, applicants };
            });

            return applications;
        } catch (error) {
            console.error('applicationList error', error);
            return [];
        }
    },
});

export const useRefreshApplicationById = (applicationId: number) => {
    const [applications, setApplications] =
        useRecoilState(applicationListState);

    const application = useRecoilValue(getApplicationById(applicationId));

    const refreshApplicationValidation = useRefreshApplicationValidation(
        application?.id
    );

    const refreshProductEligibility = useRefreshEligibility(
        application?.id,
        application?.product?.productId,
        application?.product?.productVersion
    );

    if (!application) return () => Promise.resolve();

    return async () => {
        const { data: updatedApplication } = await apiClient.getApplication(
            application.id
        );

        const applicants = updatedApplication?.applicants || [];

        Object.values(applicants).forEach((applicant, index) => {
            applicants[applicant.applicantId] = {
                ...applicant,
                applicantColor: APPLICANTS_COLORS[index % 5],
            };
        });

        const foundIndex = applications.findIndex(
            ({ id }) => id === application.id
        );

        const newList: Application[] = insertAtIndex(
            foundIndex,
            updatedApplication,
            applications
        );

        setApplications(newList);

        await Promise.all([
            refreshApplicationValidation(),
            ...(application.product ? [refreshProductEligibility()] : []),
        ]);

        return updatedApplication;
    };
};

export const useRefreshApplications = () => {
    const setApplications = useSetRecoilState(applicationListState);

    const currentApplication = useRecoilValue(selectedApplication);

    const refreshApplicationValidation = useRefreshApplicationValidation(
        currentApplication?.id
    );

    const refreshProductEligibility = useRefreshEligibility(
        currentApplication?.id,
        currentApplication?.product?.productId,
        currentApplication?.product?.productVersion
    );

    return async () => {
        const { data } = await apiClient.getApplications();

        const applications = data.map((application) => {
            const applicants = application.applicants;

            // add applicant color code for each applicant
            Object.values(application?.applicants || []).forEach(
                (applicant, index) => {
                    applicants[applicant.applicantId] = {
                        ...applicant,
                        applicantColor: APPLICANTS_COLORS[index % 5],
                    };
                }
            );

            return { ...application, applicants };
        });

        setApplications(applications);

        await Promise.all([
            ...(currentApplication?.id ? [refreshApplicationValidation()] : []),
            ...(currentApplication?.id && currentApplication?.product
                ? [refreshProductEligibility()]
                : []),
        ]);

        return applications;
    };

    // This is the recommended way to do it from Recoil.
    // But this will make all useRecoilValue to go back into loading state and make all React.Suspense
    // go into fallback prop so the screen will be flickering
    // const setApplicationListRequestID = useSetRecoilState(
    //     applicationsListRequestIDState
    // );
    // return () => {
    //     setApplicationListRequestID((requestID) => requestID + 1);
    // };
};

export const getApplication = selectorFamily({
    key: 'GetApplicationFamily',
    get:
        (applicationId: number) =>
        ({ get }) => {
            return get(getApplicationById(applicationId));
        },
});

export const getApplicationSummary = selectorFamily({
    key: 'getApplicationSummary',
    get: (applicationId: number) => async () => {
        try {
            const { data } =
                await apiClient.getApplicationSummary(applicationId);

            return data;
        } catch (error) {
            console.error('Application summary error', error);
            return {} as ApplicationSummary;
        }
    },
});

export const getValidBestProducts = selectorFamily({
    key: 'getValidBestProducts',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const { bestProducts } = get(getApplicationSummary(applicationId));

            if (
                !bestProducts?.restrictedFixed &&
                !bestProducts?.standardFixed &&
                !bestProducts?.variable
            ) {
                return {} as BestProductsType;
            }

            return bestProducts;
        },
});

export const getApplicationType = selectorFamily({
    key: 'GetApplicationTypeFamily',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const application = get(getApplication(applicationId));

            return application?.type;
        },
});

export const getApplicationLocked = selectorFamily({
    key: 'GetApplicationLocked',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const application = get(getApplication(applicationId));

            return application?.locked;
        },
});

export const getIsApplicationSubmitted = selectorFamily({
    key: 'getApplicationState',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const application = get(getApplication(applicationId));

            return [
                'SUBMITTED',
                'UNDER_REVISION',
                'REVIEWED',
                'NOTES_SUBMITTED',
                'LENDER_SUBMITTED',
                'LENDER_APPROVED',
                'PENDING_COMMITMENT_SIGNATURE',
                'PENDING_CONDITIONS',
                'COMPLETE',
                'NOTARY_ALERTED',
                'FUNDED',
                'CLOSED',
                'EXPIRED',
            ].includes(application?.applicationState);
        },
});

export const getIsAssetsValid = selectorFamily({
    key: 'getIsAssetsValid',
    get:
        ({
            applicationId,
            applicantsIds,
        }: {
            applicationId: number;
            applicantsIds: number[];
        }) =>
        ({ get }) => {
            const assets = get(
                getAssetsGroupedByApplicantIds({ applicationId, applicantsIds })
            );

            let isValid = false;

            (Object.values(assets) || []).forEach((asset) => {
                if (!isEmpty(asset?.assets)) {
                    isValid = true;
                }
            });

            return isValid;
        },
});

export const getSubjectProperty = selectorFamily({
    key: 'GetSubjectProperty',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const application = get(getApplication(applicationId));

            return application ? application?.property : undefined;
        },
});

export const getSubjectPropertyMortgage = selectorFamily({
    key: 'GetSubjectPropertyMortgage',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const subjectProperty = get(getSubjectProperty(applicationId));

            return subjectProperty?.mortgages?.[0];
        },
});

export const getTaxAuthorities = selectorFamily({
    key: 'getTaxAuthorities',
    get:
        ({
            taxAuthorityType,
            province,
        }: {
            taxAuthorityType: TaxAuthorityType;
            province: string;
        }) =>
        async () => {
            const { data } = await apiClient.getTaxAuthorities({
                types: taxAuthorityType,
                provinces: province,
            });

            return data;
        },
});

export const getApplicants = selectorFamily({
    key: 'GetApplicants',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const application = get(getApplication(applicationId));

            return application?.applicants;
        },
});

export const getApplicantsSelectOptions = selectorFamily({
    key: 'GetApplicantsSelectOptions',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const applicants = get(getApplicantsList(applicationId));

            const options: SelectOptions<number> = applicants.map(
                (applicant) => ({
                    value: applicant.applicantId,
                    label: `${applicant.firstName} ${applicant.lastName}`,
                })
            );

            return options;
        },
});

export const getApplicantsList = selectorFamily({
    key: 'GetApplicantsList',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const applicants = get(getApplicants(applicationId));

            return Object.values(applicants || {});
        },
});

export const getApplicantsCreditScoreExpired = selectorFamily({
    key: 'getApplicantsCreditScoreExpired',
    get:
        ({
            applicationId,
            applicantsIds,
        }: {
            applicationId: number;
            applicantsIds: number[];
        }) =>
        ({ get }) => {
            const applicants = get(
                getApplicantsByIds({ applicationId, applicantsIds })
            );
            const applicantsWithExpiredCreditScore: ApplicantCreditReport[] =
                [];

            (applicants || []).forEach((applicant) => {
                if (applicant?.creditReport?.expired) {
                    applicantsWithExpiredCreditScore.push({
                        firstName: applicant.firstName,
                        lastName: applicant.lastName,
                        applicantId: applicant.applicantId,
                        pullCreditDate: applicant?.creditReport?.created,
                    });
                }
            });

            return applicantsWithExpiredCreditScore;
        },
});

export const getApplicantsCreditScoreHitFalse = selectorFamily({
    key: 'getApplicantsCreditScoreHitFalse',
    get:
        ({
            applicationId,
            applicantsIds,
        }: {
            applicationId: number;
            applicantsIds: number[];
        }) =>
        ({ get }) => {
            const applicants = get(
                getApplicantsByIds({ applicationId, applicantsIds })
            );
            const applicantsWithExpiredCreditScore: ApplicantCreditReport[] =
                [];

            (applicants || []).forEach((applicant) => {
                if (
                    applicant?.creditReport?.hit === false &&
                    !applicant?.creditReport?.expired
                ) {
                    applicantsWithExpiredCreditScore.push({
                        firstName: applicant.firstName,
                        lastName: applicant.lastName,
                        applicantId: applicant.applicantId,
                        pullCreditDate: applicant?.creditReport?.created,
                    });
                }
            });

            return applicantsWithExpiredCreditScore;
        },
});

export const getApplicantsWithEid = selectorFamily({
    key: 'GetApplicantsWithEid',
    get:
        ({
            applicationId,
            applicantsIds,
        }: {
            applicationId: number;
            applicantsIds: number[];
        }) =>
        ({ get }) => {
            const applicants = get(
                getApplicantsByIds({ applicationId, applicantsIds })
            );
            const applicantsWithEid: Applicant[] = [];

            (applicants || []).forEach((applicant) => {
                if (applicant?.creditReport?.identity?.hasFlags) {
                    const reasons =
                        applicant?.creditReport?.identity?.products.filter(
                            (product) =>
                                product.identityProductId === 'EID_COMPARE'
                        )[0]?.reasons;

                    if (!isEmpty(reasons)) {
                        applicantsWithEid.push({
                            ...applicant,
                            creditReport: {
                                ...applicant.creditReport,
                                hasEidFlags: true,
                            },
                        });
                    }
                }
            });

            return applicantsWithEid;
        },
});

export const getApplicantsCreditDecisions = selectorFamily({
    key: 'getApplicantsCreditDecisions',
    get:
        ({
            applicationId,
            locale,
        }: {
            applicationId: number;
            locale: string;
        }) =>
        async () => {
            try {
                const { data } = await apiClient.getCreditDecisionLogic(
                    applicationId,
                    locale
                );
                return data.filter(
                    (creditDecision: CreditDecision) => !creditDecision.checked
                );
            } catch {
                return [];
            }
        },
});

export const useRefreshApplicantCreditDecisions = ({
    applicationId,
    locale,
}: {
    applicationId: number;
    locale: string;
}) => {
    const setApplicantCreditDecisions = useSetRecoilState(
        getApplicantCreditDecisionState({ applicationId, locale })
    );

    return async () => {
        try {
            const { data } = await apiClient.getCreditDecisionLogic(
                applicationId,
                locale
            );

            return data.filter(
                (creditDecision: CreditDecision) => !creditDecision.checked
            );
        } catch {
            setApplicantCreditDecisions([]);
            return [];
        }
    };
};

export const getApplicantCreditDecisionState = atomFamily({
    key: 'getApplicantCreditDecisionState',
    default: getApplicantsCreditDecisions,
});

export const getApplicantCreditReportHistory = selectorFamily({
    key: 'getApplicantCreditReportHistory',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        async ({ get }) => {
            try {
                const { data } =
                    await apiClient.getCreditReportsHistory(applicantId);

                const application = get(getApplication(applicationId));

                if (application.applicationState !== 'FUNDED') {
                    return data;
                }

                const applicationUpdatedDate = new Date(application.updated);

                // When application is funded, only return credit reports from before funded date (OG-8048)
                return data.filter((report) => {
                    const reportDate = new Date(report.created);
                    return reportDate <= applicationUpdatedDate;
                });
            } catch {
                return [];
            }
        },
});

export const useRefreshApplicantCreditReportHistory = (
    applicationId: number,
    applicantId: number
) => {
    const setApplicantCreditHistory = useSetRecoilState(
        getApplicantCreditReportHistoryState({ applicationId, applicantId })
    );

    return async () => {
        try {
            const { data } =
                await apiClient.getCreditReportsHistory(applicantId);

            setApplicantCreditHistory(data);

            return data;
        } catch {
            setApplicantCreditHistory([]);
            return [];
        }
    };
};

export const getApplicantCreditReportHistoryState = atomFamily({
    key: 'getApplicantCreditReportHistoryState',
    default: getApplicantCreditReportHistory,
});

export const getApplicantsCreditScoreSuccess = selectorFamily({
    key: 'getApplicantsCreditScoreSuccess',
    get:
        ({
            applicationId,
            applicantsIds,
        }: {
            applicationId: number;
            applicantsIds: number[];
        }) =>
        ({ get }) => {
            const applicants = get(
                getApplicantsByIds({ applicationId, applicantsIds })
            );
            const applicantsWithExpiredCreditScore: ApplicantCreditReport[] =
                [];

            (applicants || []).forEach((applicant) => {
                if (applicant?.creditReport?.hit) {
                    applicantsWithExpiredCreditScore.push({
                        firstName: applicant.firstName,
                        lastName: applicant.lastName,
                        applicantId: applicant.applicantId,
                        pullCreditDate: applicant?.creditReport?.created,
                    });
                }
            });

            return applicantsWithExpiredCreditScore;
        },
});

export const getApplicantsCreditScoreWithHit = selectorFamily({
    key: 'getApplicantsCreditScoreWithHit',
    get:
        ({
            applicationId,
            applicantsIds,
        }: {
            applicationId: number;
            applicantsIds: number[];
        }) =>
        ({ get }) => {
            const applicants = get(
                getApplicantsByIds({ applicationId, applicantsIds })
            );
            const applicantCreditReportHit: Applicant[] = [];

            (applicants || []).forEach((applicant) => {
                if (applicant?.creditReport?.hit) {
                    const eidReasonsFound =
                        applicant?.creditReport?.identity?.products?.filter(
                            (product) =>
                                product.identityProductId === 'EID_COMPARE'
                        )[0]?.reasons;

                    const amlFlagFound =
                        applicant?.creditReport?.identity?.products?.find(
                            (creditReportProduct) =>
                                creditReportProduct.identityProductId === 'AML'
                        );

                    applicantCreditReportHit.push({
                        ...applicant,
                        creditReport: {
                            ...applicant.creditReport,
                            hasAmlFlags: amlFlagFound?.hasFlags,
                            hasEid: !isEmpty(eidReasonsFound),
                            hasEidFlags:
                                !isEmpty(eidReasonsFound) &&
                                applicant?.creditReport?.identity
                                    ?.riskStrategyDecision !==
                                    'PASSED_VERIFICATION',
                        },
                    });
                }
            });

            return applicantCreditReportHit;
        },
});

export const getApplicantsRentalIncome = selectorFamily({
    key: 'getApplicantsRentalIncome',
    get:
        ({
            applicationId,
            applicantsIds,
        }: {
            applicationId: number;
            applicantsIds: number[];
        }) =>
        ({ get }) => {
            const subjectProperty = get(getSubjectProperty(applicationId));

            const rentalProperties = [];

            if (
                ['RENTAL', 'OWNER_OCCUPIED_AND_RENTAL'].includes(
                    subjectProperty.purpose
                )
            ) {
                rentalProperties.push({
                    address: subjectProperty.address,
                    rentalIncome: getYearAmountFrequency(
                        subjectProperty.rentalIncome.amount,
                        subjectProperty.rentalIncome.frequency
                    ),
                });
            }

            const ownedProperties = get(
                getPropertiesListByApplicantIds({
                    applicationId,
                    applicantsIds,
                })
            );

            (ownedProperties || []).forEach((property) => {
                if (
                    ['RENTAL', 'OWNER_OCCUPIED_AND_RENTAL'].includes(
                        property.afterTransactionPurpose
                    )
                ) {
                    rentalProperties.push({
                        address: property.address,
                        rentalIncome: getYearAmountFrequency(
                            property.rentalIncome.amount,
                            property.rentalIncome.frequency
                        ),
                    });
                }
            });

            return rentalProperties;
        },
});

export const getApplicantsByIds = selectorFamily({
    key: 'GetApplicantsByIds',
    get:
        ({
            applicationId,
            applicantsIds,
        }: {
            applicationId: number;
            applicantsIds: number[];
        }) =>
        ({ get }) => {
            const applicants = get(getApplicantsList(applicationId));

            return applicants.filter((applicant) =>
                applicantsIds.includes(applicant.applicantId)
            );
        },
});

export const getOtherIncomeGroupedByApplicant = selectorFamily({
    key: 'GetOtherIncomeGroupedByApplicant',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const applicants = get(getApplicantsList(applicationId));

            const otherIncomes: OtherIncomeGroupedByApplicant = {};

            (applicants || []).forEach((applicant) => {
                if (applicant.income.others.length) {
                    otherIncomes[applicant.applicantId] = {
                        applicantId: applicant.applicantId,
                        firstName: applicant.firstName,
                        lastName: applicant.lastName,
                        otherIncomes: byType(applicant.income.others),
                    };
                }
            });

            return otherIncomes;
        },
});

export const getOtherIncomeGroupedByApplicantIds = selectorFamily({
    key: 'GetOtherIncomeGroupedByApplicantIds',
    get:
        ({
            applicationId,
            applicantsIds,
        }: {
            applicationId: number;
            applicantsIds: number[];
        }) =>
        ({ get }) => {
            const applicants = get(
                getApplicantsByIds({ applicationId, applicantsIds })
            );

            const otherIncomes: OtherIncomeGroupedByApplicant = {};

            (applicants || []).forEach((applicant) => {
                if (applicant.income.others.length) {
                    otherIncomes[applicant.applicantId] = {
                        applicantId: applicant.applicantId,
                        firstName: applicant.firstName,
                        lastName: applicant.lastName,
                        applicantColor: applicant.applicantColor,
                        otherIncomes: byType(applicant.income.others),
                    };
                }
            });

            return otherIncomes;
        },
});

export const getAssetsGroupedByApplicant = selectorFamily({
    key: 'GetAssetsGroupedByApplicant',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const applicants = get(getApplicantsList(applicationId));

            const assets: AssetsGroupedByApplicant = {};

            (applicants || []).forEach((applicant) => {
                if (applicant.allAssets.length) {
                    assets[applicant.applicantId] = {
                        applicantId: applicant.applicantId,
                        firstName: applicant.firstName,
                        lastName: applicant.lastName,
                        assets: byType(applicant.allAssets),
                    };
                }
            });

            return assets;
        },
});

export const getAssetsGroupedByApplicantIds = selectorFamily({
    key: 'GetAssetsGroupedByApplicantIds',
    get:
        ({
            applicationId,
            applicantsIds,
        }: {
            applicationId: number;
            applicantsIds: number[];
        }) =>
        ({ get }) => {
            const applicants = get(
                getApplicantsByIds({ applicationId, applicantsIds })
            );

            const ownedProperties = get(
                getPropertiesListByApplicantIds({
                    applicationId,
                    applicantsIds,
                })
            );

            const assets: AssetsGroupedByApplicant = {};

            // Create assets list for each applicant
            (applicants || []).forEach((applicant) => {
                const assetsList = [];
                (applicant.allAssets || []).forEach((asset) => {
                    // Add sold properties to assets list
                    if (asset.type === 'PROPERTY') {
                        const property: any = asset.existingPropertyId
                            ? ownedProperties.find(
                                  ({ id }) => id === asset.existingPropertyId
                              )
                            : {};

                        if (property.afterTransactionPurpose === 'SOLD') {
                            assetsList.push({
                                ...asset,
                                value: property?.purchasePrice,
                            });
                        }
                    } else {
                        assetsList.push(asset);
                    }
                });

                // If applicant has assets, add them to the assets object
                if (assetsList.length) {
                    assets[applicant.applicantId] = {
                        applicantId: applicant.applicantId,
                        firstName: applicant.firstName,
                        lastName: applicant.lastName,
                        assets: byType(assetsList),
                        applicantColor: applicant.applicantColor,
                    };
                }
            });

            return assets;
        },
});

export const getIncomeMainApplicant = selectorFamily({
    key: 'getIncomeMainApplicant',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const mainApplicant = get(getMainApplicant(applicationId));

            return mainApplicant?.income?.employments;
        },
});

export const getIncomeCoApplicants = selectorFamily({
    key: 'GetIncomeCoApplicants',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const coApplicantsList = get(getCoApplicantsList(applicationId));

            const applicantsIncome = [];

            (coApplicantsList || []).forEach((applicant) => {
                if (applicant.income.employments.length) {
                    applicantsIncome.push({
                        applicant: {
                            applicantId: applicant.applicantId,
                            firstName: applicant.firstName,
                            lastName: applicant.lastName,
                        },
                        employments: applicant.income.employments,
                    });
                }
            });

            return applicantsIncome;
        },
});

export const getIncomeApplicantsIds = selectorFamily({
    key: 'GetIncomeCoApplicants',
    get:
        ({
            applicationId,
            applicantsIds,
        }: {
            applicationId: number;
            applicantsIds: number[];
        }) =>
        ({ get }) => {
            const applicants = get(
                getApplicantsByIds({ applicationId, applicantsIds })
            );

            const applicantsIncome = [];

            (applicants || []).forEach((applicant) => {
                if (applicant?.income?.employments.length) {
                    const employments = [];
                    (applicant?.income?.employments || []).forEach((income) => {
                        employments.push({
                            ...income,

                            totalIncome:
                                getYearAmountFrequency(
                                    income?.salary?.base?.amount,
                                    income?.salary?.base?.frequency
                                ) +
                                getYearAmountFrequency(
                                    income?.salary?.bonus?.amount || 0,
                                    income?.salary?.bonus?.frequency
                                ) +
                                getYearAmountFrequency(
                                    income?.salary?.commission?.amount || 0,
                                    income?.salary?.commission?.frequency
                                ) +
                                getYearAmountFrequency(
                                    income?.salary?.overtime?.amount || 0,
                                    income?.salary?.overtime?.frequency
                                ),
                        });
                    });

                    applicantsIncome.push({
                        applicant: {
                            applicantId: applicant.applicantId,
                            firstName: applicant.firstName,
                            lastName: applicant.lastName,
                            applicantColor: applicant.applicantColor,
                        },
                        employments,
                    });
                }
            });

            return applicantsIncome;
        },
});

export const getIncomeByApplicant = selectorFamily({
    key: 'GetIncomeByApplicant',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const applicantIncome = get(
                getApplicant({ applicationId, applicantId })
            );

            return applicantIncome?.income?.employments;
        },
});

export const getOtherIncomeByApplicant = selectorFamily({
    key: 'getOtherIncomeByApplicant',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const applicantIncome = get(
                getApplicant({ applicationId, applicantId })
            );

            return applicantIncome?.income?.others;
        },
});

export const getPropertiesList = selectorFamily({
    key: 'GetPropertiesList',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const applicants = get(getApplicantsList(applicationId));

            const properties: OtherProperty[] = [];

            (applicants || []).forEach((applicant) => {
                (applicant.properties || []).forEach((property) => {
                    properties.push({
                        ...property,
                        applicantId: applicant.applicantId,
                    });
                });
            });

            return Object.values(properties);
        },
});

export const getHasSomePropertiesWithBridgeLoan = selectorFamily({
    key: 'getHasSomePropertiesWithBridgeLoan',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const properties = get(getPropertiesList(applicationId));

            return properties.some(({ bridgeLoanAmount }) => bridgeLoanAmount);
        },
});

export const getPropertiesListByApplicantIds = selectorFamily({
    key: 'GetPropertiesListByApplicantIds',
    get:
        ({
            applicationId,
            applicantsIds,
        }: {
            applicationId: number;
            applicantsIds: number[];
        }) =>
        ({ get }) => {
            const applicants = get(
                getApplicantsByIds({ applicationId, applicantsIds })
            );

            const properties: OtherProperty[] = [];

            (applicants || []).forEach((applicant) => {
                (applicant.properties || []).forEach((property) => {
                    properties.push({
                        ...property,
                        applicantId: applicant.applicantId,
                    });
                });
            });

            return Object.values(properties);
        },
});

export const getPropertyById = selectorFamily({
    key: 'GetPropertyById',
    get:
        ({
            applicationId,
            propertyId,
        }: {
            applicationId: number;
            propertyId: number;
        }) =>
        ({ get }) => {
            const properties = get(getPropertiesList(applicationId));

            return properties.find((property) => property.id === propertyId);
        },
});

export const getMainApplicant = selectorFamily({
    key: 'GetMainApplicant',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const application = get(getApplication(applicationId));

            return application?.applicants?.[application.mainApplicantId];
        },
});

export const getMainApplicantCurrentAddress = selectorFamily({
    key: 'getMainApplicantCurrentAddress',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const mainApplicant = get(getMainApplicant(applicationId));

            return mainApplicant.addresses.find(
                (address) => address.isCurrentAddress
            )?.address;
        },
});

export const getApplicationEmails = selectorFamily({
    key: 'GetApplicationEmails',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const applicants = get(getApplicantsList(applicationId));

            return applicants.reduce((acc, applicant) => {
                acc[applicant.applicantId] = applicant.email;
                return acc;
            }, {});
        },
});

export const getMainApplicantId = selectorFamily({
    key: 'getMainApplicantId',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const mainApplicant = get(getMainApplicant(applicationId));

            return mainApplicant?.applicantId;
        },
});

export const getIsMainApplicant = selectorFamily({
    key: 'GetIsMainApplicant',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const application = get(getApplication(applicationId));

            return applicantId === application.mainApplicantId;
        },
});

export const getCurrentAppMainApplicant = selector({
    key: 'GetCurrentAppMainApplicant',
    get: ({ get }) => {
        const currentApplicationId = get(currentApplicationIdState);
        const application = get(getApplication(currentApplicationId));

        return application?.applicants?.[application.mainApplicantId];
    },
});

export const getCoApplicants = selectorFamily({
    key: 'GetCoApplicants',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const application = get(getApplication(applicationId));
            const applicants = get(getApplicants(applicationId));
            if (application?.mainApplicantId) {
                // Remove main applicant without mutating applicants object
                const coApplicants = {
                    ...applicants,
                    [application.mainApplicantId]: undefined,
                };
                delete coApplicants[application.mainApplicantId];

                return coApplicants;
            }
            return {};
        },
});

export const getCoApplicantsList = selectorFamily({
    key: 'GetCoApplicantsList',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const coApplicants = get(getCoApplicants(applicationId));

            return Object.values(coApplicants);
        },
});

export const getSelectedProduct = selectorFamily({
    key: 'getSelectedProduct',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const application = get(getApplication(applicationId));

            return application?.product as ApplicationProduct;
        },
});

export const getSelectedHelocProduct = selectorFamily({
    key: 'getSelectedHelocProduct',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const application = get(getApplication(applicationId));

            return application?.heloc as HelocApplication;
        },
});

export const getIsHelocStandalone = selectorFamily({
    key: 'getIsHelocStandalone',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const application = get(getApplication(applicationId));

            return (
                !application?.mortgage?.product &&
                !!Object.keys(application?.heloc || {}).length
            );
        },
});

export const getIsTermLoanStandalone = selectorFamily({
    key: 'getIsTermLoanStandalone',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const application = get(getApplication(applicationId));

            return !!application?.mortgage?.product && !application?.heloc;
        },
});

export const getApplicant = selectorFamily({
    key: 'GetApplicant',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const applicants = get(getApplicants(applicationId));

            return applicants?.[applicantId];
        },
});

export const getApplicantFullName = selectorFamily({
    key: 'GetApplicantFullName',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const applicant = get(
                getApplicant({
                    applicationId,
                    applicantId,
                })
            );

            return `${applicant.firstName} ${applicant.lastName}`;
        },
});

export const getApplicantCurrentAddress = selectorFamily({
    key: 'GetApplicantCurrentAddress',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const applicant = get(getApplicant({ applicationId, applicantId }));

            return getCurrentAddress(applicant.addresses);
        },
});

export const getApplicantInfo = selectorFamily({
    key: 'GetApplicantInfo',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const applicant = get(getApplicant({ applicationId, applicantId }));

            return adaptApplicantInfo(applicant);
        },
});

export const getMortgageAmount = selectorFamily({
    key: 'GetMortgageAmount',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const application = get(selectedApplication);
            const sumAssets = get(getSumAssets(applicationId));
            const mortgageBalance = get(getMortgagesBalance(applicationId));

            const { isNewApplication } = getApplicationMainType(
                application.type
            );

            // NEW
            // mortgageAmount = propertyValue - downPayment
            if (isNewApplication) {
                return {
                    label: 'mortgageAmount',
                    amount:
                        application.property.purchasePrice -
                        sumAssets.amountUsedForDownPayment,
                };
            }

            // RENEW
            // Add mortgageBalance (taken from mortgage info)
            // REFI
            // Add mortgageBalance (taken from mortgage info)
            return {
                label: 'mortgageBalance',
                amount: mortgageBalance,
            };
        },
});
export const getEquity = selectorFamily({
    key: 'GetEquity',
    get:
        (application: Application) =>
        ({ get }) => {
            const sumAssets = get(getSumAssets(application.id));
            const mortgageBalance = get(getMortgagesBalance(application.id));

            const { isNewApplication } = getApplicationMainType(
                application.type
            );

            if (isNewApplication) {
                return (
                    application.property.estimatedPropertyValue *
                        EIGHTY_PERCENT -
                    (application.property.purchasePrice -
                        sumAssets.amountUsedForDownPayment)
                );
            }
            // RENEW and REFI
            return (
                application.property.estimatedPropertyValue * EIGHTY_PERCENT -
                mortgageBalance
            );
        },
});
export const getClosingCost = selectorFamily({
    key: 'GetClosingCost',
    get: (application: Application) => () =>
        application.property.purchasePrice * CLOSING_COST_MULTIPLIER,
});

export const getSumApplicantAssets = selectorFamily({
    key: 'GetSumApplicantOtherIncome',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const applicant = get(getApplicant({ applicationId, applicantId }));

            const ownedProperties = get(getPropertiesList(applicationId));

            return applicant.allAssets.reduce(
                (applicantAcc, asset) => {
                    let assetValue = 0;
                    let amountUsedForDownPayment = 0;

                    if (asset.type === 'PROPERTY') {
                        const property: any = asset.existingPropertyId
                            ? ownedProperties.find(
                                  ({ id }) => id === asset.existingPropertyId
                              )
                            : {};

                        if (property.afterTransactionPurpose === 'SOLD') {
                            assetValue = property?.purchasePrice;
                            amountUsedForDownPayment =
                                asset?.amountUsedForDownPayment;
                        }
                    } else {
                        assetValue = asset.value;
                        amountUsedForDownPayment =
                            asset?.amountUsedForDownPayment;
                    }

                    return {
                        amountUsedForDownPayment:
                            applicantAcc.amountUsedForDownPayment +
                            amountUsedForDownPayment,
                        value: applicantAcc.value + assetValue,
                    };
                },
                {
                    amountUsedForDownPayment: 0,
                    value: 0,
                }
            );
        },
});

export const getSumAssets = selectorFamily({
    key: 'GetSumAssets',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const applicants = get(getApplicantsList(applicationId));

            // Sum all applicants assets values
            return applicants.reduce(
                (acc, applicant) => {
                    // Sum Applicant assets values
                    const applicantValues = get(
                        getSumApplicantAssets({
                            applicationId,
                            applicantId: applicant.applicantId,
                        })
                    );

                    return {
                        amountUsedForDownPayment:
                            acc.amountUsedForDownPayment +
                            applicantValues.amountUsedForDownPayment,
                        value: acc.value + applicantValues.value,
                    };
                },
                {
                    amountUsedForDownPayment: 0,
                    value: 0,
                }
            );
        },
});
export const getSumAssetsByApplicantIds = selectorFamily({
    key: 'GetSumAssetsByApplicantIds',
    get:
        ({
            applicationId,
            applicantsIds,
        }: {
            applicationId: number;
            applicantsIds: number[];
        }) =>
        ({ get }) => {
            const applicants = get(
                getApplicantsByIds({ applicationId, applicantsIds })
            );

            // Sum all applicants assets values
            return applicants.reduce(
                (acc, applicant) => {
                    // Sum Applicant assets values
                    const applicantValues = get(
                        getSumApplicantAssets({
                            applicationId,
                            applicantId: applicant.applicantId,
                        })
                    );

                    return {
                        amountUsedForDownPayment:
                            acc.amountUsedForDownPayment +
                            applicantValues.amountUsedForDownPayment,
                        value: acc.value + applicantValues.value,
                    };
                },
                {
                    amountUsedForDownPayment: 0,
                    value: 0,
                }
            );
        },
});

export const getDownPaymentAmount = selectorFamily({
    key: 'GetDownPaymentAmount',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const sumAssets = get(getSumAssets(applicationId));

            return sumAssets.amountUsedForDownPayment;
        },
});

// Mortgage balance exists for Renewal, Refinance
// New mortgage will return `0` since their is no balance left on a new purchase
export const getMortgagesBalance = selectorFamily({
    key: 'GetMortgagesBalance',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const application = get(getApplication(applicationId));

            // sum all mortgages array balance
            return (application.property?.mortgages || []).reduce(
                (accumulator, mortgage) => accumulator + mortgage.balance,
                0
            );
        },
});

export const getFinancingAmount = selectorFamily({
    key: 'GetFinancingAmount',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const application = get(getApplication(applicationId));
            const downPaymentAmount = get(getDownPaymentAmount(applicationId));
            const mortgageBalance = get(getMortgagesBalance(applicationId));

            const { isNewApplication, isRefinanceApplication } =
                getApplicationMainType(application.type);

            if (isNewApplication) {
                return application.property.purchasePrice - downPaymentAmount;
            }

            if (isRefinanceApplication) {
                return (
                    application.property.additionalFundAmount + mortgageBalance
                );
            }

            return mortgageBalance;
        },
});

export const getCurrentEmployers = selectorFamily({
    key: 'getCurrentEmployers',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const applicants = get(getApplicantsList(applicationId));

            const currentEmployers = [];

            (applicants || []).forEach((applicant) => {
                const incomes = get(
                    getIncomeByApplicant({
                        applicationId,
                        applicantId: applicant.applicantId,
                    })
                );

                (incomes || []).forEach((income) => {
                    if (income.isCurrent) {
                        currentEmployers.push({
                            ...income,
                            applicant: {
                                applicantId: applicant.applicantId,
                                firstName: applicant.firstName,
                                lastName: applicant.lastName,
                            },
                            totalIncome:
                                getYearAmountFrequency(
                                    income?.salary?.base?.amount,
                                    income?.salary?.base?.frequency
                                ) +
                                getYearAmountFrequency(
                                    income?.salary?.bonus?.amount || 0,
                                    income?.salary?.bonus?.frequency
                                ) +
                                getYearAmountFrequency(
                                    income?.salary?.commission?.amount || 0,
                                    income?.salary?.commission?.frequency
                                ) +
                                getYearAmountFrequency(
                                    income?.salary?.overtime?.amount || 0,
                                    income?.salary?.overtime?.frequency
                                ),
                        });
                    }
                });
            });

            return currentEmployers;
        },
});

export const getOtherIncomeList = selectorFamily({
    key: 'getOtherIncomeList',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const applicants = get(getApplicantsList(applicationId));

            const otherIncomes = [];

            (applicants || []).forEach((applicant) => {
                const otherIncomesList = get(
                    getOtherIncomeByApplicant({
                        applicationId,
                        applicantId: applicant.applicantId,
                    })
                );

                (otherIncomesList || []).forEach((otherIncome) => {
                    otherIncomes.push({
                        ...otherIncome,
                        applicant: {
                            applicantId: applicant.applicantId,
                            firstName: applicant.firstName,
                            lastName: applicant.lastName,
                        },
                        totalOtherIncome: getYearAmountFrequency(
                            otherIncome.income.amount,
                            otherIncome.income.frequency
                        ),
                    });
                });
            });

            return otherIncomes;
        },
});
export const getSumApplicantOtherIncome = selectorFamily({
    key: 'GetSumApplicantOtherIncome',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const applicant = get(getApplicant({ applicationId, applicantId }));

            return applicant.income.others.reduce(
                (applicantAcc, otherIncome) => {
                    return (
                        applicantAcc +
                        getYearAmountFrequency(
                            otherIncome.income.amount,
                            otherIncome.income.frequency
                        )
                    );
                },
                0
            );
        },
});

export const getSumCurrentIncome = selectorFamily({
    key: 'getSumCurrentIncome',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const applicant = get(getApplicant({ applicationId, applicantId }));

            const employments = applicant?.income?.employments;

            if (!employments) {
                return 0;
            }

            return employments.reduce((sumIncome, applicantIncome) => {
                if (!applicantIncome.isCurrent) {
                    return sumIncome + 0;
                }

                if (applicantIncome.incomeOverrideIncluded) {
                    return getYearAmountFrequency(
                        applicantIncome.incomeOverride.amount,
                        applicantIncome.incomeOverride.frequency
                    );
                }

                if (!applicantIncome?.salary) {
                    return sumIncome;
                }

                return (
                    sumIncome +
                    getYearAmountFrequency(
                        applicantIncome.salary.base?.amount,
                        applicantIncome.salary.base?.frequency
                    ) +
                    getYearAmountFrequency(
                        applicantIncome.salary.bonus?.amount,
                        applicantIncome.salary.bonus?.frequency
                    ) +
                    getYearAmountFrequency(
                        applicantIncome.salary.commission?.amount,
                        applicantIncome.salary.commission?.frequency
                    ) +
                    getYearAmountFrequency(
                        applicantIncome.salary.overtime?.amount,
                        applicantIncome.salary.overtime?.frequency
                    )
                );
            }, 0);
        },
});

export const getSumCurrentIncludedIncomeYearly = selectorFamily({
    key: 'getSumCurrentIncludedIncomeYearly',
    get:
        ({
            applicationId,
            applicantId,
            employmentId,
        }: {
            applicationId: number;
            applicantId: number;
            employmentId: number;
        }) =>
        ({ get }) => {
            const applicant = get(getApplicant({ applicationId, applicantId }));
            let useableYearlyIncome = 0;
            applicant.income.employments.forEach((income) => {
                if (income.id === employmentId) {
                    if (income?.incomeOverrideIncluded) {
                        useableYearlyIncome = getYearAmountFrequency(
                            income?.incomeOverride?.amount,
                            income?.incomeOverride?.frequency
                        );
                        return;
                    }
                    const base = income?.salary?.baseRatiosIncluded
                        ? getYearAmountFrequency(
                              income?.salary?.base?.amount,
                              income?.salary?.base?.frequency
                          )
                        : 0;
                    const bonus = income?.salary?.bonusRatiosIncluded
                        ? getYearAmountFrequency(
                              income?.salary?.bonus?.amount || 0,
                              income?.salary?.bonus?.frequency
                          )
                        : 0;
                    const commish = income?.salary?.commissionRatiosIncluded
                        ? getYearAmountFrequency(
                              income?.salary?.commission?.amount || 0,
                              income?.salary?.commission?.frequency
                          )
                        : 0;
                    const over = income?.salary?.overtimeRatiosIncluded
                        ? getYearAmountFrequency(
                              income?.salary?.overtime?.amount || 0,
                              income?.salary?.overtime?.frequency
                          )
                        : 0;
                    useableYearlyIncome = base + bonus + commish + over;
                }
            });
            return useableYearlyIncome;
        },
});

export const getSumOtherIncomes = selectorFamily({
    key: 'GetSumOtherIncomes',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const applicants = get(getApplicantsList(applicationId));

            // Sum all applicants other incomes
            // Sum all applicants other income values
            return applicants.reduce((acc, applicant) => {
                const applicantOtherIncomeValue = get(
                    getSumApplicantOtherIncome({
                        applicationId,
                        applicantId: applicant.applicantId,
                    })
                );

                return acc + applicantOtherIncomeValue;
            }, 0);
        },
});

export const getSumOtherIncomesByApplicantIds = selectorFamily({
    key: 'GetSumOtherIncomesByApplicantIds',
    get:
        ({
            applicationId,
            applicantsIds,
        }: {
            applicationId: number;
            applicantsIds: number[];
        }) =>
        ({ get }) => {
            const applicants = get(
                getApplicantsByIds({ applicationId, applicantsIds })
            );

            // Sum all applicants other incomes
            // Sum all applicants other income values
            return applicants.reduce((acc, applicant) => {
                const applicantOtherIncomeValue = get(
                    getSumApplicantOtherIncome({
                        applicationId,
                        applicantId: applicant.applicantId,
                    })
                );

                return acc + applicantOtherIncomeValue;
            }, 0);
        },
});

export const getHasLiabilitiesByApplicantIds = selectorFamily({
    key: 'GetHasLiabilitiesByApplicantIds',
    get:
        ({
            applicationId,
            applicantsIds,
        }: {
            applicationId: number;
            applicantsIds: number[];
        }) =>
        ({ get }) => {
            const applicants = get(
                getApplicantsByIds({ applicationId, applicantsIds })
            );

            // Return boolean stop at first applicant with liabilities
            return !!applicants.find(
                (applicant) => applicant?.liabilities?.length >= 1
            );
        },
});

export const getApplicantLiabilities = selectorFamily({
    key: 'GetApplicantLiabilities',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const applicant = get(getApplicant({ applicationId, applicantId }));

            return applicant?.liabilities || [];
        },
});

export const getSumLiabilitiesByApplicantIds = selectorFamily({
    key: 'GetSumLiabilitiesByApplicantIds',
    get:
        ({
            applicationId,
            applicantsIds,
        }: {
            applicationId: number;
            applicantsIds: number[];
        }) =>
        ({ get }) => {
            const applicants = get(
                getApplicantsByIds({ applicationId, applicantsIds })
            );

            return applicants.reduce(
                (acc, applicant) => {
                    const applicantValues = get(
                        getSumApplicantLiabilities({
                            applicationId,
                            applicantId: applicant.applicantId,
                        })
                    );

                    return {
                        highCredit: acc.highCredit + applicantValues.highCredit,
                        balance: acc.balance + applicantValues.balance,
                        yearlyPayment:
                            acc.yearlyPayment + applicantValues.yearlyPayment,
                    };
                },
                {
                    highCredit: 0,
                    balance: 0,
                    yearlyPayment: 0,
                }
            );
        },
});

export const getSumApplicantLiabilities = selectorFamily({
    key: 'GetSumApplicantLiabilities',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const applicant = get(getApplicant({ applicationId, applicantId }));

            return applicant.liabilities.reduce(
                (applicantAcc, liability) => {
                    const highCredit = liability?.highCredit || 0;
                    const balance = liability?.balance || 0;
                    const yearlyPayment = getYearAmountFrequency(
                        liability?.payment?.amount,
                        liability?.payment?.frequency
                    );

                    return {
                        highCredit: applicantAcc.highCredit + highCredit,
                        balance: applicantAcc.balance + balance,
                        yearlyPayment:
                            applicantAcc.yearlyPayment + yearlyPayment,
                    };
                },
                {
                    highCredit: 0,
                    balance: 0,
                    yearlyPayment: 0,
                }
            );
        },
});

// Get the Application entity for document
type SubCategoriesPathMap = Record<
    DocumentSubCategory | 'undefined',
    {
        path: string[];
        documentEntityType: DocumentEntityType;
    }
>;
type CategoriesPathMap = Partial<
    Record<DocumentCategory, Partial<SubCategoriesPathMap>>
>;

export const categoriesKeyStateMap: CategoriesPathMap = {
    FINANCIALS: {
        undefined: {
            path: ['allAssets'],
            documentEntityType: 'asset',
        },
        FINANCIALS_ASSETS: {
            path: ['allAssets'],
            documentEntityType: 'asset',
        },
        FINANCIALS_LIABILITIES: {
            path: ['allAssets'],
            documentEntityType: 'asset',
        },
        FINANCIALS_OTHER_FINANCIALS: {
            path: ['allAssets'],
            documentEntityType: 'asset',
        },
    },
    INCOMES: {
        INCOMES: {
            path: ['income', 'employments'],
            documentEntityType: 'incomeEmployment',
        },
        INCOMES_EMPLOYMENTS: {
            path: ['income', 'employments'],
            documentEntityType: 'incomeEmployment',
        },
        INCOMES_OTHER_INCOMES: {
            path: ['income', 'others'],
            documentEntityType: 'incomeOther',
        },
        INCOMES_PENSIONS: {
            path: ['income', 'employments'],
            documentEntityType: 'incomePension',
        },
        INCOMES_SELF_EMPLOYED: {
            path: ['income', 'employments'],
            documentEntityType: 'incomeEmployment',
        },
    },
    PROPERTIES: {
        PROPERTIES: {
            path: ['properties'],
            documentEntityType: 'property',
        },
        PROPERTIES_OTHER_PROPERTY: {
            path: ['properties'],
            documentEntityType: 'property',
        },
        PROPERTIES_OTHER_PROPERTIES: {
            path: ['properties'],
            documentEntityType: 'property',
        },
        PROPERTIES_SUBJECT_PROPERTY: {
            path: ['property'],
            documentEntityType: 'property',
        },
    },
    OTHER: {
        CREDIT: {
            path: ['liabilities'],
            documentEntityType: 'liability',
        },
    },
};
// Avoid returning full object if path is an empty array.
const entityIndexedPath = (
    path: string[]
): { path: (string | number)[]; documentEntityType?: DocumentEntityType } => {
    // @ts-ignore
    return R.pathOr(
        { path, documentEntityType: undefined },
        path.length === 0 ? ['~~NotExistsKey~~'] : path,
        categoriesKeyStateMap
    ) as { path: (string | number)[]; documentEntityType?: DocumentEntityType };
};

export const getEntitiesForDocCats = selectorFamily({
    key: 'GetEntitiesForDocCats',
    get:
        ({
            applicationId,
            applicantId,
            category,
            subcategory,
        }: {
            applicationId: number;
            applicantId: number;
            category: DocumentCategory;
            subcategory: DocumentSubCategory | 'undefined';
        }) =>
        ({ get }) => {
            const application = get(getApplication(applicationId));
            const { path, documentEntityType } = entityIndexedPath([
                category,
                subcategory,
            ]);

            let zePath: (string | number)[] = [];
            if (subcategory === 'PROPERTIES_SUBJECT_PROPERTY') {
                zePath = path;
            } else if (Array.isArray(path)) {
                zePath = ['applicants', applicantId, ...path];
            }

            const entities: any[] = R.path([...zePath], application);

            return {
                path,
                documentEntityType,
                entities: Array.isArray(entities)
                    ? entities
                    : entities && [entities],
            };
        },
});

export const getEntityDetailforDocuments = selectorFamily({
    key: 'GetEntityForDocuments',
    get:
        ({
            applicationId,
            applicantId,
            category,
            subcategory,
            entityId,
        }: {
            applicationId: number;
            applicantId: number;
            category: DocumentCategory;
            subcategory: DocumentSubCategory | 'undefined';
            entityId: number;
        }) =>
        ({ get }) => {
            const { path, documentEntityType, entities } = get(
                getEntitiesForDocCats({
                    applicationId,
                    applicantId,
                    category,
                    subcategory,
                })
            );

            let documentEntity: DocumentEntityDetails['documentEntity'] =
                undefined;
            if (entities) {
                if (subcategory === 'PROPERTIES_SUBJECT_PROPERTY') {
                    documentEntity = entities?.[0] as SubjectProperty;
                } else {
                    documentEntity = R.find(
                        R.propEq(entityId, 'id'),
                        entities
                    ) as DocumentEntityDetails['documentEntity'];
                }
            }

            return { path, documentEntityType, documentEntity };
        },
});

export const getAppHasValidEvent = selectorFamily({
    key: 'GetAppHasValidEvent',
    get:
        ({
            applicationId,
            event,
        }: {
            applicationId: number;
            event: ApplicationEvent;
        }) =>
        ({ get }) => {
            const application = get(getApplication(applicationId));

            return application?.validEvents.includes(event) || false;
        },
});

export const getIsAppFilogixSubmittable = selectorFamily({
    key: 'GetIsAppFilogixSubmittable',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const isAppFilogixSubmittable = get(
                getAppHasValidEvent({
                    applicationId,
                    event: 'SUBMIT_TO_LENDERS_DATA_EXCHANGE',
                })
            );

            return isAppFilogixSubmittable;
        },
});

export const getIsAppFilogixPushable = selectorFamily({
    key: 'GetIsAppFilogixPushable',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const isAppFilogixPushable = get(
                getAppHasValidEvent({
                    applicationId,
                    event: 'SUBMIT_TO_LENDERS_DATA_EXCHANGE',
                })
            );

            return isAppFilogixPushable;
        },
});

// Mortgage
export const getApplicationMortgageData = selectorFamily({
    key: 'GetApplicationMortgageData',
    get: (applicationId: number) => async () => {
        const { data } = await apiClient.getApplicationMortgage(applicationId);

        return data;
    },
});

export const getApplicationMortgage = atomFamily({
    key: 'GetApplicationMortgage',
    default: getApplicationMortgageData,
});

export const useRefreshApplicationMortgage = (applicationId: number) => {
    const setApplicationMortgage = useSetRecoilState(
        getApplicationMortgage(applicationId)
    );

    return async () => {
        const { data } = await apiClient.getApplicationMortgage(applicationId);

        setApplicationMortgage(data);

        return data;
    };
};

export const getApplicationServicingData = selectorFamily({
    key: 'getApplicationServicingData',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const {
                amortizationMonths,
                amortizationYears,
                investor,
                nextPaymentDate,
                payment,
                product,
            } = get(getApplicationMortgage(applicationId));
            const application = get(getApplication(applicationId));
            const property = get(getSubjectProperty(applicationId));
            const mainApplicant = get(getMainApplicant(applicationId));
            const coApplicantList = get(getCoApplicantsList(applicationId));
            const coApplicantsHaveAddress =
                coApplicantList.length &&
                coApplicantList.filter(
                    (coApplicant: Applicant) => !isEmpty(coApplicant.addresses)
                );

            const isInternalReferral = !!application?.loanNumberAtReferral;

            // most commonly missed fields when filling out an application
            const servicingFieldDataList: MissingServicingFields[] = [
                { amortizationMonths: amortizationMonths },
                { amortizationYears: amortizationYears },
                { investor: investor },
                { paymentFrequency: payment.frequency },
                { paymentAmount: payment.amount },
                { productRate: product?.rate },
                { productType: product?.type },
                { productTerm: product?.term },
                { propertyPurchasePrice: property.purchasePrice },
                { coApplicantAddress: coApplicantsHaveAddress },

                /* If the application is an Internal referral, don't include the nextPaymentDate */
                ...(isInternalReferral
                    ? []
                    : [{ nextPaymentDate: nextPaymentDate }]),

                /* If the partner is Canada Life or an Internal referral, don't include propertyListingType */
                ...(isInternalReferral ||
                mainApplicant?.partner == PARTNER.CANADA_LIFE
                    ? []
                    : [{ propertyListingType: property.propertyListingType }]),
            ];

            const missingFields = servicingFieldDataList
                .filter((fieldData) => {
                    const [dataValue] = Object.values(fieldData);

                    return isEmpty(dataValue);
                })
                .map((fieldData) => {
                    const [dataKey] = Object.keys(fieldData);

                    return dataKey;
                });

            return missingFields;
        },
});

export const currentApplicationIdState = atom({
    key: 'currentApplicationIdState',
    default: 0,
});

export const applicationListState = atom({
    key: 'applicationListState',
    default: applicationsList,
});

export const currentApplicantIdState = atom({
    key: 'currentApplicantIdState',
    default: 0,
});

// Application States
// This will check some partial states of section
// i.e. allow the application to change state to `SUBMIT`
// even with incomplete data. +- same logic as Website Application.
export const getApplicantHasBankingValid = selectorFamily({
    key: 'GetApplicantHasBankingValid',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const applicant = get(getApplicant({ applicationId, applicantId }));
            if (!applicant) return false;

            const institution =
                applicant.primaryBankingInstitution === 'OTHER'
                    ? applicant.primaryBankingInstitutionOther
                    : applicant.primaryBankingInstitution;
            return !!institution;
        },
});

export const getApplicantHasBankruptcyValid = selectorFamily({
    key: 'GetApplicantHasBankruptcyValid',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const applicant = get(getApplicant({ applicationId, applicantId }));
            if (!applicant) return false;

            return applicant?.hasConsumerProposalOrBankruptcyLast5yrs === 'NO';
        },
});

export const getApplicantOtherPropertiesValid = selectorFamily({
    key: 'GetApplicantOtherPropertiesValid',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const applicant = get(getApplicant({ applicationId, applicantId }));
            if (!applicant) return false;

            return applicant?.propertiesSpecified;
        },
});

export const getApplicantOtherIncomesValid = selectorFamily({
    key: 'GetApplicantOtherIncomesValid',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const applicant = get(getApplicant({ applicationId, applicantId }));
            if (!applicant) return false;

            return applicant?.otherIncomesSpecified;
        },
});

export const getApplicantEmploymentValid = selectorFamily({
    key: 'GetApplicantEmploymentValid',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const applicant = get(getApplicant({ applicationId, applicantId }));
            if (!applicant) return false;

            const employments = applicant.income.employments;

            return incomeArraySchema.isValid(employments);
        },
});

export const getApplicantAddressHasEnoughHistory = selectorFamily({
    key: 'GetApplicantAddressHasEnoughHistory',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const applicant = get(getApplicant({ applicationId, applicantId }));
            if (!applicant) return false;

            const addresses = applicant.addresses;

            const sums = addresses.reduce(
                (acc, address) => {
                    return {
                        ...acc,
                        years: acc.years + address.occupiedYears,
                        months: acc.months + address.occupiedMonths,
                    };
                },
                {
                    years: 0,
                    months: 0,
                }
            );

            // 3 years min of previous addresses
            const threeYearsMonths = 3 * 12;
            const months = getMonthsFromYearsMonths(sums);

            return months >= threeYearsMonths;
        },
});

export const getHasHelocAmountDiscrepancy = selectorFamily({
    key: 'GetHasHelocamountDiscrepancy',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            const application = get(getApplication(applicationId));

            if (!application) return false;

            const qualification = get(getQualification(applicationId));
            // hide message if qualification hasn't loaded or if application doesn't have heloc
            if (!qualification || !application?.heloc) return false;

            if (qualification.helocAmount !== application?.heloc?.helocAmount)
                return true;
        },
});

export const getHasMortgageAmountDiscrepancy = selectorFamily({
    key: 'GetHasMortgageAmountDiscrepancy',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            const application = get(getApplication(applicationId));

            const { isRenewalApplication } = getApplicationMainType(
                application.type
            );

            if (!application) return false;

            const qualification = get(getQualification(applicationId));

            const mortgageObject = application?.mortgage;

            if (!qualification || !mortgageObject) return false;

            if (isRenewalApplication) {
                if (
                    qualification.mortgageBalance !==
                    mortgageObject.mortgageAmount
                ) {
                    return true;
                } else {
                    return false;
                }
            }

            // If the qualification amount is null, the BE calculates the qualification amount
            const hasSetQualificationMortgageAmount =
                qualification?.mortgageAmount !== null;

            if (hasSetQualificationMortgageAmount) {
                return (
                    qualification.mortgageAmount !==
                    mortgageObject.mortgageAmount
                );
            }

            if (
                qualification?.ltvEx?.calculatedMortgageAmount !==
                mortgageObject.mortgageAmount
            ) {
                return true;
            }
        },
});

export const getApplicantEmploymentHasEnoughHistory = selectorFamily({
    key: 'getApplicantEmploymentHasEnoughHistory',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const applicant = get(getApplicant({ applicationId, applicantId }));
            if (!applicant) return false;

            const employments = applicant.income.employments;
            const employmentLength = employments.reduce(
                (acc, employment) => ({
                    years: acc.years + employment.employedYears,
                    months: acc.months + employment.employedMonths,
                }),
                { years: 0, months: 0 }
            );

            const threeYearsMonths = 3 * 12;
            const months = getMonthsFromYearsMonths(employmentLength);

            return months >= threeYearsMonths;
        },
});

export const getIsApplicantInformationValid = selectorFamily({
    key: 'GetIsApplicantInformationValid',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            const applicant = get(getApplicant({ applicationId, applicantId }));
            if (!applicant) return false;

            const isMainApplicant = get(
                getIsMainApplicant({ applicationId, applicantId })
            );

            return applicantInformationSchema.isValid(applicant, {
                context: { isMainApplicant },
            });
        },
});

export const getApplicantStates = selectorFamily({
    key: 'GetApplicantStates',
    get:
        ({
            applicationId,
            applicantId,
        }: {
            applicationId: number;
            applicantId: number;
        }) =>
        ({ get }) => {
            return {
                applicantInformation: get(
                    getIsApplicantInformationValid({
                        applicationId,
                        applicantId,
                    })
                ),
                registerAddresses: get(
                    getApplicantAddressHasEnoughHistory({
                        applicationId,
                        applicantId,
                    })
                ),
                employments: get(
                    getApplicantEmploymentValid({
                        applicationId,
                        applicantId,
                    })
                ),
                otherIncomes: get(
                    getApplicantOtherIncomesValid({
                        applicationId,
                        applicantId,
                    })
                ),
                otherProperties: get(
                    getApplicantOtherPropertiesValid({
                        applicationId,
                        applicantId,
                    })
                ),
                bankingDetails: get(
                    getApplicantHasBankingValid({
                        applicationId,
                        applicantId,
                    })
                ),
                bankruptcy: get(
                    getApplicantHasBankruptcyValid({
                        applicationId,
                        applicantId,
                    })
                ),
            };
        },
});

export const getApplicantsStates = selectorFamily({
    key: 'getApplicantsStates',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const applicants = get(getApplicantsList(applicationId));

            const states: Record<
                number,
                ReturnType<typeof getApplicantStates>
            > = applicants.reduce((acc, applicant) => {
                return {
                    ...acc,
                    [applicant.applicantId]: get(
                        getApplicantStates({
                            applicationId,
                            applicantId: applicant.applicantId,
                        })
                    ),
                };
            }, {});

            return states;
        },
});

export const getSubjectPropertyValid = selectorFamily({
    key: 'GetSubjectPropertyValid',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const property = get(getSubjectProperty(applicationId));

            // Using same as application website because we want SDR to be allow
            // to submit incomple application before transfering to an advisor.
            const schema = property?.isFound
                ? targetPropertySchema
                : targetPropertyNotFoundSchema;

            return schema.isValid(property);
        },
});

// Product Selection state
const productSelectionState = selectorFamily({
    key: 'ProductSelectionState',
    get: (applicationId: number) => async () => {
        try {
            const { data } =
                await apiClient.getProductSelectionState(applicationId);

            return data;
        } catch (error) {
            // TODO use log to raygun function when https://github.com/nestoca/back-office/pull/364 will be merge
            console.info('Error product selection state', error);
            return { selectionQuestions: [] };
        }
    },
});

export const getProductSelectionState = atomFamily({
    key: 'GetProductSelectionState',
    default: productSelectionState,
});

// AML
export const getApplicantsWithAml = selectorFamily({
    key: 'GetApplicantsWithAml',
    get:
        ({
            applicationId,
            applicantsIds,
        }: {
            applicationId: number;
            applicantsIds: number[];
        }) =>
        ({ get }) => {
            const applicantFlagged: Applicant[] = [];

            const applicants = get(
                getApplicantsByIds({ applicationId, applicantsIds })
            );

            applicants.forEach((applicant) => {
                if (
                    applicant.creditReport?.identity?.hasFlags &&
                    !applicant.creditReport?.amlSourceHit
                ) {
                    const amlFlagFound =
                        applicant?.creditReport?.identity?.products.find(
                            (creditReportProduct) =>
                                creditReportProduct.identityProductId === 'AML'
                        );

                    if (amlFlagFound) {
                        applicantFlagged.push({
                            ...applicant,
                            creditReport: {
                                ...applicant.creditReport,
                                hasAmlFlags: true,
                            },
                        });
                    }
                }
            });

            return applicantFlagged;
        },
});
export const getHasApplicantsAmlFlagged = selectorFamily({
    key: 'GetHasApplicantsAmlFlagged',
    get:
        ({
            applicationId,
            applicantsIds,
        }: {
            applicationId: number;
            applicantsIds: number[];
        }) =>
        ({ get }) => {
            const amlApplicants = get(
                getApplicantsWithAml({
                    applicationId,
                    applicantsIds,
                })
            );
            return amlApplicants?.length >= 1;
        },
});

export const getIsApplicationSyncedWithFilogix = selectorFamily({
    key: 'GetHasApplicantsAmlFlagged',
    get:
        (applicationId: number) =>
        ({ get }) => {
            const application = get(getApplicationById(applicationId));

            return application?.filogixSynched;
        },
});

export const getApplicationValidation = selectorFamily({
    key: 'GetApplicationValidation',
    get:
        ({ applicationId }: { applicationId: number }) =>
        async ({ get }) => {
            const problemsValidationState = get(getProblemsValidationMode);

            if (!applicationId || !problemsValidationState) {
                return { problems: [] };
            }

            const { data } = await apiClient.applicationValidation(
                applicationId,
                'ADVISOR_SCENARIO'
            );

            return data;
        },
});

export const useRefreshApplicationValidation = (applicationId) => {
    const setApplicationValidation = useSetRecoilState(
        applicationValidation({ applicationId })
    );
    const problemsValidationState = useRecoilValue(getProblemsValidationMode);

    return async () => {
        if (!applicationId || !problemsValidationState) {
            return { problems: [] };
        }

        const { data } = await apiClient.applicationValidation(
            applicationId,
            'ADVISOR_SCENARIO'
        );

        setApplicationValidation(data);
        return data;
    };
};

type ApplicationProblemsSidebarProps = {
    isSidebarOpen: boolean;
    section?: ProblemType;
    sidebarView: ApplicationProblemsSidebarView;
};

export const applicationProblemsSidebar = atom<ApplicationProblemsSidebarProps>(
    {
        key: 'ApplicationProblemsSidebar',
        default: {
            isSidebarOpen: false,
            section: undefined,
            sidebarView: APPLICATION_PROBLEMS_SIDEBAR_VIEW.MISSING_FIELDS,
        },
    }
);

export const getApplicationProblem = selectorFamily<
    ApplicationProblemAugmented[],
    { applicationId: number }
>({
    key: 'GetApplicationProblem',
    get:
        ({ applicationId }: { applicationId: number }) =>
        async ({ get }) => {
            const validations = get(applicationValidation({ applicationId }));
            const application = get(getApplicationById(applicationId));

            // Augment the problem with application data
            const problems = validations?.problems.map((problem) => {
                const augmentedProblem = getAugmentedApplicationProblem(
                    problem,
                    application
                );

                const applicantId = augmentedProblem?.applicantId;

                const applicant =
                    applicantId > 0
                        ? get(
                              getApplicant({
                                  applicationId: application?.id,
                                  applicantId,
                              })
                          )
                        : null;

                return {
                    ...augmentedProblem,
                    applicant,
                };
            });

            return problems ?? [];
        },
});

const byProp = (prop: string) =>
    R.groupBy((problem: ApplicationProblemAugmented) => problem[prop]);

const byApplicantId = byProp('applicantId');

const bySection = byProp('section');

/**
 * Group problems by section then applicantId
 * @param {ApplicationProblemAugmented[]} problems - array of problems
 * @returns {ApplicationProblemsGrouped} - Grouped problems
 * ```
 * Employment:
 *     Marcel:
 *         Entity Bla bla: field 1
 *         Entity Bla bla: field 2
 *         Entity Bla bla: field 3
 *         Entity Bla bla: field 4
 *     mathieu:
 *         Entity Bla bla: field 2
 *         Entity Bla bla: field 3
 * SubjectProperty:
 *     field 1
 *     field 2
 *     field 3
 * ```
 */
const groupProblems = (
    problems: ApplicationProblemAugmented[]
): ApplicationProblemsGrouped => {
    const grouped: Record<ProblemType, any> = R.pipe(
        bySection,
        // @ts-ignore
        R.map(byApplicantId)
        // We cannot group by entiy because something the problem is own to a group
        // ex: `application.applicants.1234.income.employments` need at least 3 yrs bla bla bla
        //  application.applicants.1234.income.employments[0].employer.name field required
        // R.map(R.map(byEntityId))
    )(problems) as unknown as any;

    const mortgageDetails = grouped?.mortgageDetails?.['0'];
    // this is return from the BE as error but it is the product mortgage builder
    // we dont need to track that
    // const product = grouped?.product?.['0'];
    const subjectProperty = grouped?.subjectProperty?.['0'];
    const flags = grouped?.flags?.['0'];
    const downPayment = grouped?.downPayment?.['0'];

    let groupedProblems = {
        ...grouped,
    };

    if (subjectProperty) {
        groupedProblems = {
            ...groupedProblems,
            subjectProperty,
        };
    }

    if (mortgageDetails) {
        groupedProblems = {
            ...groupedProblems,
            mortgageDetails,
        };
    }

    if (flags) {
        groupedProblems = {
            ...groupedProblems,
            flags,
        };
    }

    if (downPayment) {
        groupedProblems = {
            ...groupedProblems,
            downPayment,
        };
    }

    return groupedProblems;
};

export const getPropertySelectors = selector({
    key: 'GetPropertySelectors',
    get: ({ get }) => {
        const applications = get(applicationListState);
        const application = get(selectedApplication);
        const sumAssets = get(getSumAssets(application.id));
        const mortgageBalance = get(getMortgagesBalance(application.id));
        const financingAmount = get(getFinancingAmount(application.id));
        const applicants = get(getApplicantsList(application.id));
        const currentApplicationId = get(currentApplicationIdState);
        const mainApplicant = get(getMainApplicant(currentApplicationId));
        const ownedProperties = get(getPropertiesList(application.id));
        const applicantSumAssets = get(
            getSumApplicantAssets({
                applicationId: application.id,
                applicantId: mainApplicant.applicantId,
            })
        );
        const closingCost = get(getClosingCost(application));
        const mortgageAmount = get(getMortgageAmount(application.id));
        const equity = get(getEquity(application));

        return {
            applications,
            application,
            sumAssets,
            mortgageBalance,
            mortgageAmount,
            financingAmount,
            applicants,
            mainApplicant,
            applicantSumAssets,
            closingCost,
            ownedProperties,
            equity,
        };
    },
});

export const getDashboardApplicantSelector = selectorFamily({
    key: 'GetDashboardApplicantSelector',
    get:
        ({ applicantId }: { applicantId: number }) =>
        ({ get }) => {
            const applicationId = get(currentApplicationIdState);

            const totalIncome = get(
                getSumCurrentIncome({ applicationId, applicantId })
            );
            const isMainApplicant = get(
                getIsMainApplicant({ applicationId, applicantId })
            );

            return {
                applicationId,
                totalIncome,
                isMainApplicant,
            };
        },
});

export const getApplicationProblemsGrouped = selectorFamily({
    key: 'GetApplicationProblemsGrouped',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            get(applicationValidation({ applicationId }));
            const problems = get(getApplicationProblem({ applicationId }));

            // @ts-ignore
            return groupProblems(problems);
        },
});

export const getProblemsBySection = selectorFamily({
    key: 'GetProblemsBySection',
    get:
        ({
            applicationId,
            section,
        }: {
            applicationId: number;
            section: ProblemType;
        }) =>
        ({ get }) => {
            get(applicationValidation({ applicationId }));

            const problemsBySection = get(
                getApplicationProblemsGrouped({ applicationId })
            );

            return problemsBySection?.[section];
        },
});

export const getProblemsSections = selectorFamily({
    key: 'GetProblemsSections',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            get(applicationValidation({ applicationId }));

            const problems = get(
                getApplicationProblemsGrouped({ applicationId })
            );

            return Object.keys(problems) || [];
        },
});

export const getProblemsSectionsWithCount = selectorFamily({
    key: 'GetProblemsSectionsWithCount',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            get(applicationValidation({ applicationId }));

            const problems = get(
                getApplicationProblemsGrouped({ applicationId })
            );

            const problemsSections: Problems[] = [];

            (Object.keys(problems) || []).forEach((section) => {
                if (!HIDDEN_SECTIONS.includes(section)) {
                    let totalProblems = 0;
                    const problemsBySection = get(
                        getProblemsBySection({
                            applicationId,
                            section: ProblemType[section],
                        })
                    );

                    if (Array.isArray(problemsBySection)) {
                        totalProblems += problemsBySection.length;
                    } else {
                        Object.keys(problemsBySection || []).forEach(
                            (applicantId) => {
                                totalProblems +=
                                    problemsBySection[applicantId].length;
                            }
                        );
                    }

                    problemsSections.push({
                        section: ProblemType[section],
                        totalProblems,
                    });
                }
            });

            return problemsSections;
        },
});

export const getApplicationProblemsSummary = selectorFamily<
    ApplicationProblemsSummary,
    { applicationId: number }
>({
    key: 'GetApplicationProblemsSummary',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            const groupedProblems = get(
                getApplicationProblemsGrouped({ applicationId })
            );
            const sections = get(
                getProblemsSectionsWithCount({ applicationId })
            );

            return {
                groupedProblems,
                sections,
            };
        },
});

export const getHasProblems = selectorFamily({
    key: 'GetHasProblems',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            const sections = get(getProblemsSections({ applicationId }));

            const hasValidSection = sections.some(
                (section) => !HIDDEN_SECTIONS.includes(section)
            );

            return hasValidSection;
        },
});

export const getGroupedExportServicingProblems = selectorFamily({
    key: 'GetGroupedExportServicingProblems',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            const validation = get(applicationValidation({ applicationId }));
            const application = get(getApplicationById(applicationId));
            const property = get(getSubjectProperty(applicationId));
            const mainApplicant = get(getMainApplicant(applicationId));
            const { investor, nextPaymentDate } = get(
                getApplicationMortgage(applicationId)
            );

            const isInternalReferral = !!application?.loanNumberAtReferral;
            const isCanadaLifePartner =
                mainApplicant?.partner == PARTNER.CANADA_LIFE;

            const hasInvestor = !isEmpty(investor);
            const hasNextPaymentDate = !isEmpty(nextPaymentDate);
            const hasPropertyListingType = !isEmpty(
                property?.propertyListingType
            );
            const hasFinancialInstitutionAccountNumber = !isEmpty(
                mainApplicant?.financialInstitutionAccountNumber
            );

            // Mimic response from the BE, since these come pre-translated
            const detail = {
                en: 'error.fieldRequired',
                fr: 'error.fieldRequired',
            };

            // Copy validation problems since recoil state is immutable
            const validationProblems = [...(validation?.problems || [])];

            if (!hasFinancialInstitutionAccountNumber) {
                validationProblems.push({
                    source: `application.applicants.${mainApplicant?.applicantId}.financialInstitutionAccountNumber`,
                    detail,
                });
            }

            if (!hasInvestor) {
                validationProblems.push({
                    source: 'application.mortgage.investor',
                    detail,
                });
            }

            /* If not Internal referral and nextPaymentDate is missing, add to the validation */
            if (!isInternalReferral && !hasNextPaymentDate) {
                validationProblems.push({
                    source: 'application.mortgage.nextPaymentDate',
                    detail,
                });
            }

            // If not an Internal Referral or Canada Life Partner, and propertyListingType is missing, add to validation.
            if (
                !isInternalReferral &&
                !isCanadaLifePartner &&
                !hasPropertyListingType
            ) {
                validationProblems.push({
                    source: 'application.property.propertyListingType',
                    detail,
                });
            }

            // Augment validation problem with application data
            const augmentedApplicationProblem = validationProblems.map(
                (problem: ApplicationProblem) => {
                    const augmentedProblem = getAugmentedApplicationProblem(
                        problem,
                        application
                    );

                    const applicantId = augmentedProblem?.applicantId;

                    const applicant =
                        applicantId > 0
                            ? get(
                                  getApplicant({
                                      applicationId: application?.id,
                                      applicantId,
                                  })
                              )
                            : null;

                    return {
                        ...augmentedProblem,
                        applicant,
                    };
                }
            );

            return groupProblems(augmentedApplicationProblem);
        },
});

export const getHasExportServicingProblems = selectorFamily({
    key: 'GetHasExportServicingProblems',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            const exportServicingProblems = get(
                getExportServicingProblemsSummary({ applicationId })
            );

            if (!exportServicingProblems?.sections) return false;

            return exportServicingProblems.sections.length > 0;
        },
});

export const getExportServicingProblemsSummary = selectorFamily<
    ApplicationProblemsSummary,
    { applicationId: number }
>({
    key: 'GetExportServicingProblemsSummary',
    get:
        ({ applicationId }: { applicationId: number }) =>
        ({ get }) => {
            const groupedProblems = get(
                getGroupedExportServicingProblems({ applicationId })
            );

            const sections =
                getVisibleProblemSectionsWithCount(groupedProblems);

            return {
                groupedProblems,
                sections,
            };
        },
});

export const applicationValidation = atomFamily({
    key: 'ApplicationValidation',
    default: getApplicationValidation,
});

export const getIsChangeApplicationActive = selector({
    key: 'GetIsChangeApplicationActive',
    get: ({ get }) => {
        const application = get(selectedApplication);

        return ACTIVE_CHANGE_APPLICATION_TYPE_STATES.includes(
            application?.applicationState
        );
    },
});

export const getApplicantIdSelected = atomFamily({
    key: 'getApplicantIdSelected',
    default: getMainApplicantId,
});

export const sidebarNotification = atom<boolean>({
    key: 'SidebarNotification',
    default: false,
    effects_UNSTABLE: [persistAtom],
});

export const getApplicationApplicantAccountMatch = selectorFamily({
    key: 'getApplicationApplicantAccountMatch',
    get: (applicationId: number) => async () => {
        try {
            const { data } =
                await apiClient.getApplicationApplicantAccountMatch(
                    applicationId
                );

            return data;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(
                `[Error while getting the applicants accounts match] : ${error}`
            );

            return null;
        }
    },
});

export const useRefreshApplicationApplicantAccountMatch = (
    applicationId: number
) =>
    useRecoilRefresher_UNSTABLE(
        getApplicationApplicantAccountMatch(applicationId)
    );

export const useTenantSupportsTransferType = () => {
    const { settings: tenantTransactionTypes } = useTenantSetting(
        'tenantTransactionTypes'
    );

    if (!tenantTransactionTypes?.length) {
        return false;
    }

    const tenantHasRenewalAndTransferTypes = [
        TRANSACTION_TYPE.RENEWAL,
        TRANSACTION_TYPE.TRANSFER,
    ].every((type) => tenantTransactionTypes.includes(type));

    return tenantHasRenewalAndTransferTypes;
};

export const getCATAllocationsData = selectorFamily({
    key: 'getCATAllocationsData',
    get: () => (): CATAllocationsResponse => {
        return { allocation_notes: undefined, priority_list: undefined };
    },
});

export const getCATAllocations = atomFamily({
    key: 'getCATAllocations',
    default: getCATAllocationsData,
});

export const useRefreshCATAllocations = () => {
    const CATAllocationsFetcher = async (applicationId: number) => {
        const { data } = await apiClient.getCATAllocations(applicationId);
        return data;
    };

    return useRefreshRecoilState<number, CATAllocationsResponse>({
        recoilStateGetter: getCATAllocations,
        fetcher: CATAllocationsFetcher,
        validator: (applicationId) => !!applicationId,
    });
};

export const getApplicationSnapshots = selectorFamily({
    key: 'getApplicationSnapshots',
    get:
        ({
            applicationId,
            includeSnapshotData,
        }: {
            applicationId: number;
            includeSnapshotData?: boolean;
        }) =>
        async () => {
            const { data } = await apiClient.getApplicationSnapshots(
                applicationId,
                includeSnapshotData
            );

            return data;
        },
});

export const getApplicationSnapshotsDiff = selectorFamily({
    key: 'getApplicationSnapshotsDiff',
    get:
        ({
            applicationId,
            snapshotId,
        }: {
            applicationId: number;
            snapshotId: number;
        }) =>
        async () => {
            const { data } = await apiClient.getApplicationSnapshotsDiff(
                applicationId,
                snapshotId
            );

            return data;
        },
});

const _self = { selectedApplication };

export default _self;
