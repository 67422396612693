import { SEGMENT } from 'constants/appConstants';

import { Address } from './address';
import { HelocApplication } from './heloc';

import type { Applicant } from './applicant';
import type { Product } from './product';
import type { OtherProperty, SubjectProperty } from './property';
import type {
    AmountRecurringFrequency,
    RecurringFrequency,
    SemiMonthlyFrequency,
} from 'types';

export enum SectionKeys {
    application = 'application',
    applicants = 'applicants',
    employments = 'employments',
    assetsAndDownPayment = 'assetsAndDownPayment',
    ownedProperties = 'ownedProperties',
    otherIncome = 'otherIncome',
    subjectProperty = 'subjectProperty',
    liabilities = 'liabilities',
    mortgageDetails = 'mortgageDetails',
    qualification = 'qualification',
    account = 'account',
    helocDetails = 'helocDetails',
}

export enum SDRSectionKeys {
    identification = 'Identification',
    property = 'Property',
    employment = 'employments',
    ownedProperties = 'ownedProperties',
    downPayment = 'downPayment',
}

// https://github.com/nestoca/applications/blob/fc2721741e4ffcf84a28aaed24e6c7435c81c770/api/applications.go#L81-L93
export enum CreatedBy {
    CLIENT = 'CLIENT',
    BROKER = 'BROKER',
    EXTERNAL_BROKER = 'EXTERNAL_BROKER',
    MIGRATION = 'MIGRATION',
}

export type LoanType = '' | 'STANDARD' | 'HELOC' | 'ALL_IN_ONE';

export type Application = {
    id: number;
    applicants: Record<number, Applicant>;
    applicationState: ApplicationState;
    bridgeLoanNumber?: string;
    created: string;
    mainApplicantId: number;
    preQualificationId: number;
    preQualificationIdSpecified: boolean;
    product?: ApplicationProduct;
    heloc?: HelocApplication;
    isPort: boolean;
    isNonDigital: boolean;
    property: SubjectProperty;
    subPartnerID: number;
    type: ApplicationType;
    updated: string;
    uwOwnerName: string;
    validEvents: ApplicationEvent[];
    partnerAtSubmission?: string;
    loanNumberAtReferral?: string;
    loanType: LoanType;
    mortgage: ApplicationMortgage;
    filogixSynched: boolean;
    locked?: boolean;
    filogixApplicationId: string;
    sdrQualified: boolean;
    padApplicantID?: number;
    investorContractId?: string;
    investorName?: string;
    // TODO: Move the below fields to a tenant aware type
    // This requires adding types to the store
    // JIRA: https://nestoca.atlassian.net/browse/OG-1793
    preApprovalGoLiveDate?: string;
    preApprovalType?: string;
    portProperty?: OtherProperty;
    segment?: Segment; // Only for Canada Life and IG, can be ommitted
    createdBy: CreatedBy;
    externalDealId?: string;
    conditionSet: number;
    commissionType?: CommissionType;
};

export type Segment = (typeof SEGMENT)[keyof typeof SEGMENT];

/**
 * @ref https://github.com/nestoca/applications/blob/c0ca5b3ace89033027430831e9e4f584614dd6e4/api/applications_product.go#L8
 */
export type ApplicationProduct = {
    notSureYet: boolean;
    productId: number;
    productName: string;
    productVersion?: number;
} & Pick<
    Product,
    | 'lenderId'
    | 'type'
    | 'term'
    | 'insurable'
    | 'insurance'
    | 'purpose'
    | 'amortization'
    | 'amortizationMax'
    | 'rate'
    | 'bestRate'
    | 'ratePrimeVariance'
    | 'rateHold'
    | 'prepaymentIncreaseOption'
>;

/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L868
 */
export type ApplicationState =
    | 'CREATED'
    | 'SUBMITTED'
    | 'UNDER_REVISION'
    | 'REVIEWED'
    | 'NOTES_SUBMITTED'
    | 'LENDER_SUBMITTED'
    | 'LENDER_APPROVED'
    | 'PENDING_COMMITMENT_SIGNATURE'
    | 'PENDING_CONDITIONS'
    | 'COMPLETE'
    | 'NOTARY_ALERTED'
    | 'FUNDED'
    | 'CLOSED'
    | 'EXPIRED';

/**
 * @ref https://github.com/nestoca/applications/blob/a9712a455412655f0383044c62f235243ac0e644/api/applications_validation.go#L850
 */
export type ApplicationEvent =
    | 'SUBMIT'
    | 'START_REVISION'
    | 'REVIEW'
    | 'SUBMIT_TO_LENDERS_DATA_EXCHANGE'
    | 'SUBMIT_NOTES'
    | 'SUBMIT_TO_LENDER'
    | 'LENDER_APPROVE'
    | 'SUBMIT_COMMITMENT_SIGNATURE'
    | 'MISSING_CONDITIONS'
    | 'ALL_CONDITIONS_FULFILLED'
    | 'ALERT_NOTARY'
    | 'FUND'
    | 'CLOSE'
    | 'EXPIRE';

export type FlagType = {
    checked: boolean;
    code: string;
    created: string;
    updated: string;
    applicantId?: number;
};

export type BestProductsType = {
    restrictedFixed: Product;
    standardFixed: Product;
    variable: Product;
};

export type ApplicationSummary = {
    affordability: {
        propertyValue: number;
        mortgageAmount: number;
        additionalAmount: number;
        insuranceRequired: boolean;
        insurancePremiumAmount: number;
        insurancePremiumTaxAmount: number;
        limitedByDownPaymentAmount: boolean;
        gds: number;
        maxGDS: number;
        tds: number;
        maxTDS: number;
    };
    bestProducts: BestProductsType;
    flagSummary: { flags: FlagType[] };
    ltv: number;
    totalAssetsValueAmount: number;
    totalLiabilitiesAmount: number;
    netWorthAmount: number;
};

export type CompoundPeriod = 'SEMIANNUALLY' | 'MONTHLY';

export enum LineOfCreditRepaymentType {
    LOC_REPAYMENT_5YEARS = 'LOC_REPAYMENT_5YEARS',
    LOC_REPAYMENT_10YEARS = 'LOC_REPAYMENT_10YEARS',
    LOC_REPAYMENT_UNDETERMINED = 'LOC_REPAYMENT_UNDETERMINED',
}

export enum MortgageFeature {
    MORE_RESTRICTIONS = 'LOW',
}

export enum MortgageInsurer {
    CMHC = 'CMHC',
    SAGEN = 'SAGEN',
    CANADA_GUARANTY = 'CANADA_GUARANTY',
}

export enum InsurerSubmissionLender {
    UNI = 'UNI',
    NESTO = 'NESTO',
    IG = 'IG',
}

export enum Investor {
    NATIONAL_BANK = 'NBF8',
    TD = 'TDSI3',
    NATIONAL_BANK_UNINSURABLE = 'National Bank Uninsurable',
    LAURENTIAN_BANK = 'LB',
    IG = 'IG',
    NESTO_WAREHOUSE = 'WH',
    PEOPLES_TRUST_COMPANY = 'PTC',
    CMLS = 'CMLS',
    CMLS_FINANCIAL = 'CMLS Financial',
}

export enum InvestorId {
    NATIONAL_BANK = 1,
    TD = 2,
    NATIONAL_BANK_UNINSURABLE = 3,
    LAURENTIAN_BANK = 4,
    IG = 5,
    NESTO_WAREHOUSE = 6,
    PEOPLES_TRUST_COMPANY = 9,
    CMLS = 11,
    CMLS_FINANCIAL = 13,
}

export const INVESTOR_PRODUCT_ACCENT_COLOR_MAP = {
    [InvestorId.LAURENTIAN_BANK]: '#3D66B0',
    [InvestorId.NATIONAL_BANK_UNINSURABLE]: '#FF9100',
    [InvestorId.NATIONAL_BANK]: '#D52E1F',
    [InvestorId.NESTO_WAREHOUSE]: '#ECC00E',
    [InvestorId.PEOPLES_TRUST_COMPANY]: '#FFFFFF',
    [InvestorId.TD]: '#51B23F',
    [InvestorId.CMLS]: '#FFFFFF',
    [InvestorId.CMLS_FINANCIAL]: '#FFFFFF',
};

export const LIABILITY_STATUS = {
    CURRENT: 'CURRENT',
    ARREARS: 'ARREARS',
    DEFAULT: 'DEFAULT',
} as const;

export type LiabilityStatus =
    (typeof LIABILITY_STATUS)[keyof typeof LIABILITY_STATUS];

export type ApplicationMortgage = {
    finalPrimeVariance?: number;
    firstPaymentDate?: string; // Date
    firstPaymentDateOverride?: string; // Date
    maturityDate?: string; // Date
    maturityDateOverride?: string; // Date
    payment: AmountRecurringFrequency;
    monthlyPayment: AmountRecurringFrequency;
    product?: Product;
    amortization: number;
    paymentFrequency: RecurringFrequency | '';
    semiMonthlyOption?: SemiMonthlyFrequency;
    actualPaymentAmount: number;
    qualifyingPaymentAmount: number;
    compoundPeriod: CompoundPeriod;
    purposeDescription: string;
    closingDate?: string; // Date
    financialWaiverDate?: string; // Date
    interestAdjustedDate?: string; // Date
    improvementAmount?: number;
    approvedCombinedGDS?: number;
    approvedCombinedTDS?: number;
    feature: MortgageFeature | '';
    cashbackPaidAmount?: number;
    bulkInsurance?: boolean;
    bulkInsuranceCalculation?: boolean;
    bulkInsuranceOverride?: boolean;
    blendRateAdjustment: number;
    blendedAmortization?: boolean;
    bridgeFee: number;
    fundingAdministrativeFee: number;
    hasCollateralCharge?: boolean;
    liabilityStatus: LiabilityStatus | null;
    lineOfCreditRepaymentOption?: LineOfCreditRepaymentType | '';
    nextPaymentDate?: string;
    investor?: Investor | '';
    insured: boolean;
    insurer?: MortgageInsurer | '';
    nestoFees: number;
    dueToFCT: number;
    dueToNesto: number;
    dischargeFee: number;
    transferFee: number;
    commitmentIssuedDate: string;
    commitmentExpiryDate: string;
    insuranceCertificateNumber: string;
    amortizationYears: number;
    amortizationMonths: number;
    portFee: number;
    premiumCharged: boolean;
    premiumChargeAmount: number;
    premiumTaxAmount: number;
    primeVarianceOverride: number;
    productTermMonthsOverride: number;
    rateOverride: number;
    rateExpirationDate: string;
    daysToClose?: number;
    finalRate?: number;
    transactionType?: ApplicationType;
    isPreApproval?: boolean;
    rateLock?: boolean;
    rateLockDate?: string;
    rateLockDateOverride?: string;
    rateExpirationDateOverride?: string;
    finalCommission?: number;
    finalCommissionBPS?: number;
    rateDiscountBPS?: number;
    rateLockExpirationDate?: string;
    rateLockExpirationDateOverride?: string;
    solicitorOrFCT: SolicitorOrFCTType | null;
    solicitor: SolicitorFields | null;
    bridgeLoanSolicitor: SolicitorFields | null;
    originalGuaranteedAmount?: number;
    originalMortgageActRegistrationDate?: string;
    isAmortizationForcedByClient?: boolean | null;
    isBlendedRate?: boolean | null;
    loanNumber: string;
    mpiInsurer: MPIInsurer | null;
    mortgageAmount?: number;
    penaltyAmount: number;
    renewalDate?: string;
    lumpSumAmount: number;

    // Servicing Side fields override
    // https://github.com/nestoca/applications/blob/7e87c5621018190369356ef7f5475287ee01f31a/api/applications_mortgage.go#L132
    apr?: number;
    paymentAmount?: string;
    lastPaymentDate?: string;
    balanceAmount?: string;
    buydownRatio?: number;
    principalAmount?: string;
    interestAmount?: string;
    servicingFinalRate?: number;
    isLenderToUndertakeAdvance: boolean | null;
    currentBalance?: number;
    remainingAmortization?: number;
    originalPaymentAmount?: number;
    currentFrequency?: number;
    remainingAmortizationInMonths?: number;
    costOfBorrowing?: number;
};

export type SolicitorOrFCTType = 'SOLICITOR' | 'FCT';

export enum MPIInsurer {
    CANADA_LIFE = 'Canada Life',
    POLICY_ME = 'PolicyMe',
}

export type SolicitorFields = {
    firmName: string;
    lawyerName: string;
    address: Address;
    phoneNumber: string;
    email: string;
};

export type MortgageAmortization = {
    apr: number;
    paymentAmount: string;
    lastPaymentDate: string;
    balanceAmount: string;
    principalAmount: string;
    interestAmount: string;
    finalRate: number;
    remainingAmortizationInMonths: number;
    costOfBorrowing?: number;
};

// This represents the list of mortgage fields that are writable
export type UpdateApplicationMortgage = Partial<
    Pick<
        ApplicationMortgage,
        | 'amortization'
        | 'purposeDescription'
        | 'financialWaiverDate'
        | 'interestAdjustedDate'
        | 'compoundPeriod'
        | 'paymentFrequency'
        | 'amortizationYears'
        | 'amortizationMonths'
        | 'approvedCombinedGDS'
        | 'approvedCombinedTDS'
        | 'lineOfCreditRepaymentOption'
        | 'feature'
        | 'investor'
        | 'insurer'
        | 'bulkInsurance'
        | 'bulkInsuranceCalculation'
        | 'bulkInsuranceOverride'
        | 'actualPaymentAmount'
        | 'qualifyingPaymentAmount'
        | 'cashbackPaidAmount'
        | 'nextPaymentDate'
        | 'blendedAmortization'
        | 'premiumChargeAmount'
        | 'premiumTaxAmount'
        | 'premiumCharged'
        | 'insuranceCertificateNumber'
        | 'commitmentIssuedDate'
        | 'nestoFees'
        | 'dueToFCT'
        | 'rateOverride'
        | 'firstPaymentDate'
        | 'firstPaymentDateOverride'
        | 'primeVarianceOverride'
        | 'isPreApproval'
        | 'rateDiscountBPS'
        | 'rateLockDate'
        | 'rateLockExpirationDate'
        | 'rateLockExpirationDateOverride'
        | 'rateLockDateOverride'
        | 'semiMonthlyOption'
        | 'solicitor'
        | 'solicitorOrFCT'
        | 'mpiInsurer'
        | 'mortgageAmount'
        | 'currentBalance'
        | 'remainingAmortization'
        | 'originalPaymentAmount'
        | 'currentFrequency'
        | 'maturityDate'
        | 'maturityDateOverride'
        | 'productTermMonthsOverride'
        | 'blendRateAdjustment'
        // closingDate on update is a Date converted to a string
    > & { closingDate: Date | string }
>;

export type MissingServicingFields = {
    [key in ServicingFields]+?: any;
};

export enum ServicingFields {
    'amortizationMonths' = 'amortizationMonths',
    'amortizationYears' = 'amortizationYears',
    'insurer' = 'insurer',
    'investor' = 'investor',
    'nextPaymentDate' = 'nextPaymentDate',
    'paymentFrequency' = 'paymentFrequency',
    'paymentAmount' = 'paymentAmount',
    'productRate' = 'productRate',
    'productType' = 'productType',
    'productTerm' = 'productTerm',
    'propertyPurchasePrice' = 'propertyPurchasePrice',
    'propertyListingType' = 'propertyListingType',
    'coApplicantAddress' = 'coApplicantAddress',
}

export type PreApprovalType = 'LITE' | 'FORMAL';

export const MAIN_APPLICATION_TYPE = {
    NEW: 'NEW',
    RENEWAL: 'RENEWAL',
    REFINANCE: 'REFINANCE',
} as const;

export const APPLICATION_TYPE = {
    ...MAIN_APPLICATION_TYPE,
    PORT: 'PORT',
    PRE_APPROVAL: 'PRE_APPROVAL',
    ASSUMPTION: 'ASSUMPTION',
    CHANGE_OF_COVENANT: 'CHANGE_OF_COVENANT',
    CONSTRUCTION_LOAN: 'CONSTRUCTION_LOAN',
    READVANCE: 'READVANCE',
    BRIDGE: 'BRIDGE',
    TRANSFER: 'TRANSFER',
    PURCHASE_WITH_IMPROVEMENT: 'PURCHASE_WITH_IMPROVEMENT',
} as const;

export const MAIN_APPLICATION_TYPES = Object.values(MAIN_APPLICATION_TYPE);

export const APPLICATION_TYPES = Object.values(APPLICATION_TYPE);

export type MainApplicationType = (typeof MAIN_APPLICATION_TYPES)[number];

export type ApplicationType = (typeof APPLICATION_TYPES)[number];

export const APPLICATION_TYPE_MAP: Record<
    MainApplicationType,
    ReadonlyArray<ApplicationType>
> = {
    [MAIN_APPLICATION_TYPE.NEW]: [
        APPLICATION_TYPE.NEW,
        APPLICATION_TYPE.PORT,
        APPLICATION_TYPE.PRE_APPROVAL,
        APPLICATION_TYPE.ASSUMPTION,
        APPLICATION_TYPE.CHANGE_OF_COVENANT,
        APPLICATION_TYPE.CONSTRUCTION_LOAN,
        APPLICATION_TYPE.BRIDGE,
        APPLICATION_TYPE.PURCHASE_WITH_IMPROVEMENT,
    ],
    [MAIN_APPLICATION_TYPE.REFINANCE]: [
        APPLICATION_TYPE.REFINANCE,
        APPLICATION_TYPE.READVANCE,
    ],
    [MAIN_APPLICATION_TYPE.RENEWAL]: [
        APPLICATION_TYPE.RENEWAL,
        APPLICATION_TYPE.TRANSFER,
    ],
} as const;

export type ApplicationMainType = {
    mainType: MainApplicationType;
    isNewApplication: boolean;
    isRefinanceApplication: boolean;
    isRenewalApplication: boolean;
};

export type InsurerSubmissionCode = {
    key: string;
    value: string;
};

export type SectionError = {
    title: string;
    body: string;
    fields?: string[];
    // Values are keys for translations https://lingui.dev/tutorials/react#macros-vs-components
    values?: Record<string, string | number>;
};

export type CATAllocationNote = {
    allocation_note_code: string;
    allocation_note_id: number;
    allocation_note_text: string;
    allocation_note_type: number;
};

export type CATPriorityList = {
    insurer_contract_id: string;
    insurer_contract_title: string;
    insurer_name: string;
    investor_contract_id: string;
    investor_contract_title: string;
    investor_name: string;
    line_of_business: string;
    pi_score: number;
    priority: number;
};

export type CATAllocationsResponse = {
    allocation_notes: CATAllocationNote[];
    priority_list: CATPriorityList[];
};

export type CommissionType = 'UPFRONT' | 'DEFERRED';

export type UpdateApplicationProduct = {
    product: ApplicationProduct;
};

export type UpdateCommission = {
    commissionType: CommissionType;
};
