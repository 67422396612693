import { amountFrequencyTo, amountFrequencyWithRationTo } from 'utils/fns';

import type { Frequency } from 'types';
import type {
    ApplicantsIncome,
    ApplicantsLiability,
    CalculationExecutionPlanType,
    PropertyLiability,
    Qualification,
} from 'types/qualification';

export const propertyLiabilitySameFrequency = (
    propertyLiability: PropertyLiability,
    frequency: Frequency
): PropertyLiability => ({
    ...propertyLiability,
    condoFees: propertyLiability.condoFees.ratio
        ? amountFrequencyWithRationTo(propertyLiability.condoFees, frequency)
        : amountFrequencyTo(propertyLiability.condoFees, frequency),
    heatingCost: amountFrequencyTo(propertyLiability.heatingCost, frequency),
    generalExpenses: propertyLiability.generalExpenses.ratio
        ? amountFrequencyWithRationTo(
              propertyLiability.generalExpenses,
              frequency
          )
        : amountFrequencyTo(propertyLiability.generalExpenses, frequency),
    mortgagePayment: amountFrequencyTo(
        propertyLiability.mortgagePayment,
        frequency
    ),
    taxes: amountFrequencyTo(propertyLiability.taxes, frequency),
    total: amountFrequencyTo(propertyLiability.total, frequency),
});

export const applicantLiabilitySameFrequency = (
    applicantLiability: ApplicantsLiability,
    frequency: Frequency
): ApplicantsLiability => {
    return {
        ...applicantLiability,
        total: amountFrequencyTo(applicantLiability.total, frequency),
        payment:
            applicantLiability.payment &&
            amountFrequencyTo(applicantLiability.payment, frequency),
    };
};

export const otherDebtObligationsSameFrequency = (
    otherDebtObligations: CalculationExecutionPlanType['otherDebtObligations'],
    frequency: Frequency
) => {
    return {
        ...otherDebtObligations,
        applicantsLiabilities:
            otherDebtObligations?.applicantsLiabilities?.map(
                (applicantsLiability) => ({
                    ...applicantsLiability,
                    ...applicantLiabilitySameFrequency(
                        applicantsLiability,
                        frequency
                    ),
                })
            ) || [],
        otherPropertiesLiabilities:
            otherDebtObligations?.otherPropertiesLiabilities?.map(
                (property) => ({
                    ...property,
                    ...propertyLiabilitySameFrequency(property, frequency),
                })
            ) || [],
        total: amountFrequencyTo(otherDebtObligations.total, frequency),
    };
};

export const grossAnnualIncomeSameFrequency = (
    grossAnnualIncome: CalculationExecutionPlanType['grossAnnualIncome'],
    frequency: Frequency
) => {
    return {
        ...grossAnnualIncome,
        applicantsTotalIncomes:
            grossAnnualIncome?.applicantsTotalIncomes?.map((entity) => ({
                ...entity,
                ...amountFrequencyTo(entity, frequency),
            })) || [],
        otherPropertiesRentalIncomes:
            grossAnnualIncome?.otherPropertiesRentalIncomes?.map((entity) => ({
                ...entity,
                ...amountFrequencyWithRationTo(entity, frequency),
            })) || [],
        subjectPropertyRentalIncome: {
            ...grossAnnualIncome.subjectPropertyRentalIncome,
            ...amountFrequencyWithRationTo(
                grossAnnualIncome.subjectPropertyRentalIncome,
                frequency
            ),
        },
        total: amountFrequencyTo(grossAnnualIncome.total, frequency),
    };
};

export const calculationExecutionPlanSameFrequency = (
    calculationExecutionPlan: CalculationExecutionPlanType,
    frequency: Frequency
) => {
    if (!calculationExecutionPlan) {
        return null;
    }

    const {
        grossAnnualIncome,
        otherDebtObligations,
        subjectPropertyLiabilities,
    } = calculationExecutionPlan;
    return {
        ...calculationExecutionPlan,
        grossAnnualIncome: grossAnnualIncomeSameFrequency(
            grossAnnualIncome,
            frequency
        ),
        otherDebtObligations: otherDebtObligationsSameFrequency(
            otherDebtObligations,
            frequency
        ),
        subjectPropertyLiabilities: propertyLiabilitySameFrequency(
            subjectPropertyLiabilities,
            frequency
        ),
    };
};

export const applicantsIncomeSameFrequency = (
    applicantsIncome: ApplicantsIncome[] = [],
    frequency
) => {
    return (
        applicantsIncome?.map((entity) => ({
            ...entity,
            // Here's the catch. BE will return this with no frequency.
            // value is always annual from BE.
            // So we need to convert it to the frequency we want from 'ANNUALLY' hard coded.
            employmentTotal:
                amountFrequencyTo(
                    { amount: entity.employmentTotal, frequency: 'ANNUALLY' },
                    frequency
                )?.amount || 0,
            otherTotal:
                amountFrequencyTo(
                    { amount: entity.otherTotal, frequency: 'ANNUALLY' },
                    frequency
                )?.amount || 0,
        })) || []
    );
};

export const qualificationSameFrequency = (
    qualification: Qualification,
    frequency: Frequency
) => {
    return {
        ...qualification,
        calculationExecutionPlan: calculationExecutionPlanSameFrequency(
            qualification.calculationExecutionPlan,
            frequency
        ),
        helocQualifyingPayment: amountFrequencyTo(
            qualification?.helocQualifyingPayment,
            frequency
        ),
        payment: amountFrequencyTo(qualification.payment, frequency),
        applicantsIncome: applicantsIncomeSameFrequency(
            qualification?.applicantsIncome,
            frequency
        ),
    };
};
