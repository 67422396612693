import React, { useEffect, useRef } from 'react';

import { Typography } from '@nestoca/ui';
import { toast } from 'react-toastify';

import { logIssueModalProps } from 'components/modals/global-modal-wrapper';
import { useI18n } from 'providers/i18n/use-i18n';

export const LogIssueModal = ({ rid }: logIssueModalProps) => {
    const { i18n } = useI18n();
    const toastOpenedRef = useRef(null);

    useEffect(() => {
        if (!rid && !toastOpenedRef.current) {
            toastOpenedRef.current = true;
            toast.error(
                <Typography>{i18n._('logIssueModal.error')}</Typography>,
                {
                    autoClose: 5000,
                    closeButton: false,
                    closeOnClick: true,
                }
            );
        }
    }, [i18n, rid]);

    return (
        <Typography size={0}>
            {i18n._(
                rid
                    ? 'logIssueModal.description'
                    : 'logIssueModal.descriptionWithoutRid'
            )}
        </Typography>
    );
};
