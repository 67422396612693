import yup from './yup-extended';

export const targetPropertyNotFoundSchema = yup.object().shape({
    purpose: yup.string().min(1).required(),
    propertyType: yup.string().min(1).required(),
    propertyTypeOverride: yup.string().optional().nullable().max(32),
    purchasePrice: yup.number().min(0).required(),
});

export const targetPropertySchema = yup.object().shape({
    purpose: yup.string().min(1).required(),
    constructionType: yup.string().min(1).required(),
    propertyType: yup.string().min(1).required(),
    propertyTypeOverride: yup.string().optional().nullable().max(32),
    tenure: yup.string().min(1).required(),
    waterType: yup.string().min(1).required(),
    propertyStyle: yup.string().min(1).required(),
    yearBuilt: yup.number().min(0).required(),
    estimatedPropertyValue: yup.number().min(0).required(),
});

// Validate if we have base amount and frequency only when *NOT* of type *NONE*
const incomeSchema = yup.object().shape({
    salary: yup.object().when('incomeType', (incomeType, schema) =>
        incomeType === 'NONE'
            ? schema
            : yup.object().shape({
                  base: yup.object().shape({
                      amount: yup.number().required(),
                      frequency: yup.string().min(1).required(),
                  }),
              })
    ),
});

export const incomeArraySchema = yup.array().of(incomeSchema).min(1);

export const applicantInformationSchema = yup.object().shape({
    firstName: yup.string().min(1).required(),
    lastName: yup.string().min(1).required(),
    dateOfBirth: yup.string().min(2).required(),
    phone: yup.string().min(10).required(),
    maritalStatus: yup.string().min(2).required(),
    relationToMainApplicant: yup.string().when('$isMainApplicant', {
        is: false,
        then: yup.string().min(1).required(),
        otherwise: yup.string().min(0).nullable(),
    }),
});
